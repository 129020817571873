import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PrevalenceIndicatorsComponent} from './pages/prevalence-indicators/prevalence-indicators.component';
import {ProcessIndicatorsComponent} from './pages/process-indicators/process-indicators.component';
import {ResultIndicatorsComponent} from './pages/result-indicators/result-indicators.component';
import {ClinicIndicatorsComponent} from './pages/clinic-indicators/clinic-indicators.component';
import {DoctorIndicatorsComponent} from './pages/doctor-indicators/doctor-indicators.component';

const routes: Routes = [
  {
    path: 'prevalence-indicators',
    component: PrevalenceIndicatorsComponent
  },
  {
    path: 'process-indicators',
    component: ProcessIndicatorsComponent
  },
  {
    path: 'result-indicators/:id',
    component: ResultIndicatorsComponent,
    children: [
      {
        path: 'metabolic-control',
        component: ResultIndicatorsComponent,
      },
      {
        path: 'lipid-control',
        component: ResultIndicatorsComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'albumin-excretion',
        component: ResultIndicatorsComponent,
      },
      {
        path: 'kidney-function',
        component: ResultIndicatorsComponent,
      },
      {
        path: 'impaired-kidney-function',
        component: ResultIndicatorsComponent,
      }
    ]
  },
  {
    path: 'clinic-indicators',
    component: ClinicIndicatorsComponent,
  },
  {
    path: 'doctor-indicators',
    component: DoctorIndicatorsComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MedGeoDataRoutingModule { }
