import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { ChartDB } from '../../../fack-db/chart-data';
import {ApexChartService} from '../../../theme/shared/components/chart/apex-chart/apex-chart.service';
import {MedicalInfo} from '../../../model/medical-info';
import {Measure} from '../../../model/measure';
import {ChartInfo} from '../../../model/chart-info';
import {SessionService} from '../../../services/session.service';
import {Router} from '@angular/router';
import {Spinkit} from '../../../theme/shared/components/spinner/spinkits';

@Component({
  selector: 'app-dash-analytics',
  templateUrl: './dash-analytics.component.html',
  styleUrls: ['./dash-analytics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashAnalyticsComponent implements OnInit {
  public medicalInfo: MedicalInfo = null;
  public measures: Measure[] = [];
  public charts: ChartInfo[] = [];
  public clinicsChart: any = null;
  public doctorsChart: any = null;

  constructor(private session: SessionService, private router: Router) {
    SessionService.newData.subscribe(
      result => {
          this.refreshInfo();
      }
    );
  }


  ngOnInit() {
    this.session.setContentLoading(true);
    this.session.getMedicalInfo().subscribe(
      (result) => {
        // Avisar al componente de filtros la primera vez
        SessionService.newData.emit(true);

        this.refreshInfo();
        this.session.setContentLoading(false);
      },
      error => {
        // TODO - Devolver error
        this.session.setContentLoading(false);
      }
    );
  }

  private createClinicsChart() {
    const self = this;
    const series = [];
    const basic = [];
    const advanced = [];
    const categories = [];
    for (const clinic of this.medicalInfo.centerControls) {
      basic.push(clinic.basic.mean.percentege);
      advanced.push(clinic.advanced.mean.percentege);
      categories.push(clinic.name);
    }
    series.push({
      name: 'Básico',
      data: basic
    });
    series.push({
      name: 'Avanzado',
      data: advanced
    });

    this.clinicsChart = {
      series,
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 1) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
        height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories,
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      legend: {
        position: 'top',
        labels: {
          useSeriesColors: true
        },
        markers: {
          fillColors: ['#59e0c5', '#e67776'],
        },
      }
    };
  }
  private createDoctorsChart() {
    const self = this;
    const series = [];
    const basic = [];
    const advanced = [];
    const categories = [];
    for (const doctor of this.medicalInfo.doctorControls) {
      basic.push(doctor.basic.mean.percentege);
      advanced.push(doctor.advanced.mean.percentege);
      categories.push(doctor.name);
    }
    series.push({
      name: 'Básico',
      data: basic
    });
    series.push({
      name: 'Avanzado',
      data: advanced
    });

    this.doctorsChart = {
      series,
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 1) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
        height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories,
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      legend: {
        position: 'top',
        labels: {
          useSeriesColors: true
        },
        markers: {
          fillColors: ['#59e0c5', '#e67776'],
        },
      }
    };
  }
  private createMetabolicControlChart() {
    const self = this;
    this.medicalInfo.metabolicControl.id = 'metabolic-control';
    this.medicalInfo.metabolicControl.title = 'Control Metabólico';
    this.medicalInfo.metabolicControl.chart = {
      tooltip: {
        x: {
          show: true,
          format: 'dd MMM',
          formatter:  (val, opts) => {
            return opts.w.config.xaxis.categories[opts.seriesIndex];
          }
        },
        y: {
          formatter:  (val, opts) => {
            let result = '';
            switch (opts.seriesIndex) {
              case 0:
                result = 'Muestra el porcentaje de pacientes con HbA1c<7%, respecto al total de la población seleccionada';
                break;
              case 1:
                result = 'Muestra el porcentaje de pacientes con HbA1c>7% y <10%, respecto al total de la población seleccionada';
                break;
              case 2:
                result = 'Muestra el porcentaje de pacientes con HbA1c>10%, respecto al total de la población seleccionada';
                break;
            }
            return result;
          },
          title: {
            formatter: (seriesName) => undefined
          },
        }
      },
      series: [
        {
          name: 'Buen Control',
          data: [
            this.medicalInfo.metabolicControl.good.current.percentege]
        },
        {
          name: 'Intermedio',
          data: [
            this.medicalInfo.metabolicControl.moderate.current.percentege]
        },
        {
          name: 'Mal Control',
          data: [
            this.medicalInfo.metabolicControl.bad.current.percentege
          ]
        }
      ],
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 0 && self.medicalInfo.metabolicControl.bad.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 1 && self.medicalInfo.metabolicControl.moderate.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 2 && self.medicalInfo.metabolicControl.good.current.indicator === false) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
        height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      xaxis: {
        categories: ['Buen Control', 'Intermedio', 'Mal Control'],
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter:  (val, opts) => {
          return val.toFixed(2);
        }
      },
      legend: {
        show: false,
        position: 'top'
      }
    };
  }
  private createLipidControlChart() {
    const self = this;
    this.medicalInfo.lipidControl.id = 'lipid-control';
    this.medicalInfo.lipidControl.title = 'Control Lipídico';
    this.medicalInfo.lipidControl.chart = {
      tooltip: {
        x: {
          show: true,
          format: undefined,
          formatter:  (val, opts) => {
            return opts.w.config.xaxis.categories[opts.seriesIndex];
          }
        },
        y: {
          formatter:  (val, opts) => {
            let result = '';
            switch (opts.seriesIndex) {
              case 0:
                result = 'Muestra el porcentaje de pacientes con LDL Colesterol < 130 mg/dL, respecto al total de la población seleccionada';
                break;
              case 1:
                result = 'Muestra el porcentaje de pacientes con LDL Colesterol >130 y <160 mg/dL, respecto al total de la población seleccionada';
                break;
              case 2:
                result = 'Muestra el porcentaje de pacientes con LDL Colesterol >160 mg/dL, respecto al total de la población seleccionada';
                break;
            }
            return result;
          },
          title: {
            formatter: (seriesName) => undefined
          },
        }
      },
      series: [
        {
          name: 'Bajo Riesgo',
          data: [
            this.medicalInfo.lipidControl.low.current.percentege]
        },
        {
          name: 'Riesgo Moderado',
          data: [
            this.medicalInfo.lipidControl.moderate.current.percentege]
        },
        {
          name: 'Alto Riesgo',
          data: [
            this.medicalInfo.lipidControl.high.current.percentege]
        }
      ],
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 0 && self.medicalInfo.lipidControl.high.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 1 && self.medicalInfo.lipidControl.moderate.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 2 && self.medicalInfo.lipidControl.low.current.indicator === false) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
         height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      xaxis: {
        categories: ['Bajo Riesgo', 'Riesgo Moderado', 'Alto Riesgo'],
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter:  (val, opts) => {
          return val.toFixed(2);
        },
      },
      legend: {
        show: false,
        position: 'top'
      }
    };
  }
  private createAlbuminExcretionChart() {
    const self = this;
    this.medicalInfo.albuminExcretion.id = 'albumin-excretion';
    this.medicalInfo.albuminExcretion.title = 'Excreción Albuminia';
    this.medicalInfo.albuminExcretion.chart = {
      tooltip: {
        x: {
          show: true,
          format: undefined,
          formatter:  (val, opts) => {
            return opts.w.config.xaxis.categories[opts.seriesIndex];
          }
        },
        y: {
          formatter:  (val, opts) => {
            let result = '';
            switch (opts.seriesIndex) {
              case 0:
                result = 'Muestra el porcentaje de pacientes con Índice Albumina/Creatinina <30 mg/g, respecto al total de la población seleccionada';
                break;
              case 1:
                result = 'Muestra el porcentaje de pacientes con Índice Albumina/Creatinina >30 y < de 300 mg/g, respecto al total de la población seleccionada';
                break;
              case 2:
                result = 'Muestra el porcentaje de pacientes con Índice Albumina/Creatinina >300 mg/g, respecto al total de la población seleccionada';
                break;
              case 3:
                result = 'Muestra el porcentaje de pacientes con 2 resultados del Índice Albúmina/Creatinina > 300 mg/g separados por más de 90 días, respecto al total de la población seleccionada';
                break;
            }
            return result;
          },
          title: {
            formatter: (seriesName) => undefined
          },
        }
      },
      series: [
        {
          name: 'Normal',
          data: [
            this.medicalInfo.albuminExcretion.normal.current.percentege]
        },
        {
          name: 'Inc. Moderado',
          data: [
            this.medicalInfo.albuminExcretion.moderate.current.percentege]
        },
        {
          name: 'Inc. Severo',
          data: [
            this.medicalInfo.albuminExcretion.severe.current.percentege]
        },
        {
          name: 'Nefropatía',
          data: [
            this.medicalInfo.albuminExcretion.nephropathy.current.percentege]
        }

      ],
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 0 && self.medicalInfo.albuminExcretion.nephropathy.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 1 && self.medicalInfo.albuminExcretion.severe.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 2 && self.medicalInfo.albuminExcretion.moderate.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 3 && self.medicalInfo.albuminExcretion.normal.current.indicator === false) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
         height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      xaxis: {
        categories: ['Normal', 'Inc. Moderado', 'Inc. Severo', 'Nefropatía'],
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter:  (val, opts) => {
          return val.toFixed(2);
        },
      },
      legend: {
        show: false,
        position: 'top'
      }
    };
  }
  private createKidnedyFunctionChart() {
    const self = this;
    this.medicalInfo.kidneyFunction.id = 'kidney-function';
    this.medicalInfo.kidneyFunction.title = 'Función Renal';
    this.medicalInfo.kidneyFunction.chart = {
      tooltip: {
        y: {
          formatter:  (val, opts) => {
            let result = '';
            switch (opts.seriesIndex) {
              case 0:
                result = 'Muestra el porcentaje de pacientes con EFG > 60 mL/min, respecto al total de la población seleccionada';
                break;
              case 1:
                result = 'Muestra el porcentaje de pacientes con EFG > 45 y < 60 mL/min, respecto al total de la población seleccionada';
                break;
              case 2:
                result = 'Muestra el porcentaje de pacientes con EFG < 45 mL/min, respecto al total de la población seleccionada';
                break;
            }
            return result;
          },
          title: {
            formatter: (seriesName) => undefined
          },
        }
      },
      series: [
        {
          name: 'Normal',
          data: [
            this.medicalInfo.kidneyFunction.normal.current.percentege]
        },
        {
          name: 'Moderado',
          data: [
            this.medicalInfo.kidneyFunction.moderate.current.percentege]
        },
        {
          name: 'Disminuida',
          data: [
            this.medicalInfo.kidneyFunction.low.current.percentege]
        }
      ],
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 0 && self.medicalInfo.kidneyFunction.low.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 1 && self.medicalInfo.kidneyFunction.moderate.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 2 && self.medicalInfo.kidneyFunction.normal.current.indicator === false) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
         height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      xaxis: {
        categories: ['Normal', 'Moderado', 'Disminuida'],
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter:  (val, opts) => {
          return val.toFixed(2);
        },
      },
      legend: {
        show: false,
        position: 'top'
      }
    };
  }
  private createImpairedKidneyFunctionChart() {
    const self = this;
    this.medicalInfo.impairedKidneyFunction.id = 'impaired-kidney-function';
    this.medicalInfo.impairedKidneyFunction.title = 'Función Renal Alterada y excreción de albumina preservada';
    this.medicalInfo.impairedKidneyFunction.chart = {
      tooltip: {
        y: {
          formatter:  (val, opts) => {
            let result = '';
            switch (opts.seriesIndex) {
              case 0:
                result = 'Muestra el porcentaje de pacientes con EFG > 45 y < 60 mL/min y además IMAC < 30 mg/g, respecto al total de la población seleccionada';
                break;
              case 1:
                result = 'Muestra el porcentaje de pacientes con EFG < 45 mL/min y además IMAC < 30 mg/g, respecto al total de la población seleccionada';
                break;
            }
            return result;
          },
          title: {
            formatter: (seriesName) => undefined
          },
        }
      },
      series: [
        {
          name: 'Deterioro Moderado',
          data: [
            this.medicalInfo.impairedKidneyFunction.moderate.current.percentege]
        },
        {
          name: 'Deterioro Severo',
          data: [
            this.medicalInfo.impairedKidneyFunction.severe.current.percentege]
        }
      ],
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 0 && self.medicalInfo.impairedKidneyFunction.moderate.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 1 && self.medicalInfo.impairedKidneyFunction.severe.current.indicator === false) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
         height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      xaxis: {
        categories: ['Deterioro Moderado', 'Deterioro Severo'],
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter:  (val, opts) => {
          return val.toFixed(2);
        },
      },
      legend: {
        show: false,
        position: 'top'
      }
    };
  }
  private createImpairedKidneyFunctionStagesChart() {
    const self = this;
    this.medicalInfo.impairedKidneyFunctionStages.id = 'impaired-kidney-function-stages';
    this.medicalInfo.impairedKidneyFunctionStages.title = 'Función Renal Deterioro Severo y excreción de albumina preservada';
    this.medicalInfo.impairedKidneyFunctionStages.chart = {
      tooltip: {
        y: {
          formatter:  (val, opts) => {
            let result = '';
            switch (opts.seriesIndex) {
              case 0:
                result = 'Muestra el porcentaje de pacientes con EFG > 45 y < 60 mL/min y además IMAC < 30 mg/g, respecto al total de la población seleccionada';
                break;
              case 1:
                result = 'Muestra el porcentaje de pacientes con EFG > 30 y < 45 mL/min y además IMAC < 30 mg/g, respecto al total de la población seleccionada';
                break;
              case 2:
                result = 'Muestra el porcentaje de pacientes con EFG > 15 y < 30 mL/min y además IMAC < 30 mg/g, respecto al total de la población seleccionada';
                break;
              case 3:
                result = 'Muestra el porcentaje de pacientes con EFG < 15 mL/min y además IMAC < 30 mg/g, respecto al total de la población seleccionada';
                break;
            }
            return result;
          },
          title: {
            formatter: (seriesName) => undefined
          },
        }
      },
      series: [
        {
          name: 'Estadio 3A',
          data: [
            this.medicalInfo.impairedKidneyFunctionStages.stage3A.current.percentege]
        },
        {
          name: 'Estadio 3B',
          data: [
            this.medicalInfo.impairedKidneyFunctionStages.stage3B.current.percentege]
        },
        {
          name: 'Estadio 4',
          data: [
            this.medicalInfo.impairedKidneyFunctionStages.stage4.current.percentege]
        },
        {
          name: 'Estadio 5',
          data: [
            this.medicalInfo.impairedKidneyFunctionStages.stage5.current.percentege]
        }
      ],
      colors: [({ value, seriesIndex, w }) => {
        let result = '#59e0c5';
        if (seriesIndex === 2 && self.medicalInfo.impairedKidneyFunctionStages.stage3A.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 3 && self.medicalInfo.impairedKidneyFunctionStages.stage3B.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 4 && self.medicalInfo.impairedKidneyFunctionStages.stage4.current.indicator === false) {
          result = '#e67776';
        } else if (seriesIndex === 5 && self.medicalInfo.impairedKidneyFunctionStages.stage5.current.indicator === false) {
          result = '#e67776';
        }
        return result;
      }],
      chart: {
        type: 'bar',
        height: 250
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      xaxis: {
        categories: ['Estadio 3A', 'Estadio 3B', 'Estadio 4', 'Estadio 5'],
        labels: {
          trim: true,
          show: true,
          rotate: -90,
        }
      },
      yaxis: {
        decimalsInFloat: 2
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter:  (val, opts) => {
          return val.toFixed(2);
        },
      },
      legend: {
        show: false,
        position: 'top'
      }
    };
  }

  goToIndicators(id) {
    switch (id) {
      case 'diabetes-prevalence':
      case 'hidden-diabetes':
      case 'pre-diabetes':
        this.router.navigate(['main/prevalence-indicators']);
        break;
      case 'ldl':
      case 'hba1c':
      case 'albuminuria':
        this.router.navigate(['main/process-indicators']);
        break;
      case 'metabolic-control':
      case 'lipid-control':
      case 'albumin-excretion':
      case 'kidney-function':
      case 'impaired-kidney-function':
      case 'impaired-kidney-function-stages':
        this.router.navigate(['main/result-indicators', id]);
        break;
    }

  }

  private refreshInfo() {
    this.session.getMedicalInfo().subscribe(
      result => {
        this.medicalInfo = result;
        this.measures = [];
        // tslint:disable-next-line:max-line-length
        this.medicalInfo.diabetesPrevalence.tip = 'Muestra el porcentaje de pacientes que están identificados como Diabéticos en la base de datos de Farmacia, respecto al total de la población seleccionada';
        this.medicalInfo.hiddenDiabetes.tip = 'Muestra el porcentaje de pacientes que cumplen los criterios para ser definidos como Diabéticos pero que no están identificados como tales en la base de datos de Farmacia, respecto al total de la población seleccionada';
        this.medicalInfo.preDiabetes.tip = 'Muestra el porcentaje de pacientes que cumplen los criterios para ser definidos como prediabéticos, respecto al total de la población seleccionada';
        this.medicalInfo.hba1c.tip = 'Numero de Diabéticos conocidos a los que se les solicita al menos dos HbA1c al año respecto al total de la población seleccionada.';
        this.medicalInfo.ldl.tip = 'Numero de Diabéticos conocidos a los que se les solicita al menos un Colesterol LDL al año respecto al total de la población seleccionada.';
        this.medicalInfo.albuminuria.tip = 'Numero de Diabéticos conocidos a los que se les solicita al menos una Albúmina en orina al año, respecto al total de la población seleccionada.';

        this.measures.push(this.medicalInfo.diabetesPrevalence);
        this.measures.push(this.medicalInfo.hiddenDiabetes);
        this.measures.push(this.medicalInfo.preDiabetes);
        this.measures.push(this.medicalInfo.hba1c);
        this.measures.push(this.medicalInfo.ldl);
        this.measures.push(this.medicalInfo.albuminuria);

        // Crear las gráficas
        this.createMetabolicControlChart();
        this.createLipidControlChart();
        this.createAlbuminExcretionChart();
        this.createKidnedyFunctionChart();
        this.createImpairedKidneyFunctionChart();
        this.createImpairedKidneyFunctionStagesChart();
        this.charts = [];

        this.charts.push(this.medicalInfo.metabolicControl);
        this.charts.push(this.medicalInfo.lipidControl);
        this.charts.push(this.medicalInfo.albuminExcretion);
        this.charts.push(this.medicalInfo.kidneyFunction);
        this.charts.push(this.medicalInfo.impairedKidneyFunction);
        this.charts.push(this.medicalInfo.impairedKidneyFunctionStages);

        this.createClinicsChart();
        if (this.medicalInfo.doctorControls) {
          this.createDoctorsChart();
        }
      }
    );
  }

  getMeasureText(id: string) {
    let result = 'Objetivo';
    switch (id) {
      case 'diabetes-prevalence':
      case 'pre-diabetes':
      case 'hidden-diabetes':
        result = 'Entorno';
        break;
    }
    return result;
  }

  /**
   *
   * @param id
   * @param tip 0 = indicador, 1 = total, 2 = año anterior
   */
  getMeasureToolTipText(id: string, tip: number): string {
    let result = '';
    let concat = '';
    if (tip === 1) {
      concat = ' Total';
    } else if (tip === 2) {
      concat = ' Año Anterior';
    }
    switch (id) {
      case 'diabetes-prevalence':
        result = 'Prevalencia de Diabetes en la población seleccionada';
        break;
      case 'pre-diabetes':
        result = 'Prevalencia de Pre-Diabetes en la población seleccionada';
        break;
      case 'hidden-diabetes':
        result = 'Prevalencia de Diabetes Oculta en la población seleccionada';
        break;
      case 'ldl':
        result = '% Diabéticos con uno o mas controles anuales de LDL';
        if (tip === 1) {
          result = 'Objetivo: ' + result;
        }
        break;
      case 'hba1c':
        result = '% Diabéticos con dos o mas controles anuales de HbA1C';
        if (tip === 1) {
          result = 'Objetivo: ' + result;
        }
        break;
      case 'albuminuria':
        result = '% Diabéticos con uno o mas controles anuales de Albuminuria';
        if (tip === 1) {
          result = 'Objetivo: ' + result;
        }
        break;
    }
    return result + concat;
  }
}
