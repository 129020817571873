import {EventEmitter, Injectable, Output, Query} from '@angular/core';
import {MedicalInfo} from '../model/medical-info';
import {MedicalInfoService} from './medical-info.service';
import {Observable} from 'rxjs';
import {QueryOptions} from './query-options';
import {Spinkit} from '../theme/shared/components/spinner/spinkits';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(private medicalInfoProvider: MedicalInfoService) {
  }
  private static medicalInfo: MedicalInfo = null;
  @Output() static contentLoading: EventEmitter<boolean> = new EventEmitter();
  @Output() static newData: EventEmitter<boolean> = new EventEmitter();

  static filters: {
    year: string;
    month: string;
    province: string;
    postalCode: string;
    town: string;
    clinic: string;
    doctor: string;
    gender: string;
    age: string;
  } = {
    year: '2020',
    month: '6',
    province: '21',
    postalCode: '',
    town: '',
    clinic: '',
    doctor: '',
    gender: 'Total',
    age: 'Total'
  };
  public spinnerType: string =  Spinkit.skCubeGrid;

  public setContentLoading(value: boolean) {
    SessionService.contentLoading.emit(value);
  }

  public getFilterOptions(additionalOptions?: any): QueryOptions {
    const tmpOptions = SessionService.filters;
    Object.keys(SessionService.filters).forEach((value) => {
      if (SessionService.filters[value] === '' || typeof SessionService.filters[value] === 'undefined') {
        delete tmpOptions[value];
      }
    });
    if (additionalOptions) {
      Object.assign(tmpOptions, additionalOptions);
    }
    return new QueryOptions(tmpOptions);
  }
  public loadMedicalInfo(): Observable<MedicalInfo> {
    return new Observable(observer => {

      this.medicalInfoProvider.list(this.getFilterOptions()).subscribe(
        result => {
          SessionService.medicalInfo = result[0];
          // Asignar títulos e ids
          SessionService.medicalInfo.diabetesPrevalence.id = 'diabetes-prevalence';
          SessionService.medicalInfo.hiddenDiabetes.id = 'hidden-diabetes';
          SessionService.medicalInfo.preDiabetes.id = 'pre-diabetes';
          SessionService.medicalInfo.ldl.id = 'ldl';
          SessionService.medicalInfo.hba1c.id = 'hba1c';
          SessionService.medicalInfo.albuminuria.id = 'albuminuria';

          SessionService.medicalInfo.diabetesPrevalence.title = 'Prevalencia Diabetes';
          SessionService.medicalInfo.hiddenDiabetes.title = 'Prevalencia Diabetes Oculta';
          SessionService.medicalInfo.preDiabetes.title = 'Prevalencia Pre-diabetes';
          SessionService.medicalInfo.hba1c.title = 'Control HbA1C';
          SessionService.medicalInfo.ldl.title = 'Control LDL';
          SessionService.medicalInfo.albuminuria.title = 'Control Albuminuria';
          observer.next(SessionService.medicalInfo);
        },
        error => {
          observer.error(error);
        });
    });
  }

  public setMedicalInfo(medicalInfo: MedicalInfo) {
    SessionService.medicalInfo = medicalInfo;
  }

  public getMedicalInfo(): Observable<MedicalInfo> {
    return new Observable(observer => {
      if (SessionService.medicalInfo == null) {
        this.loadMedicalInfo().subscribe(
          result => {
            observer.next(result);
          }
        );
      } else {
        observer.next(SessionService.medicalInfo);
      }
    });
  }

  getFilters() {
    return SessionService.filters;
  }
}
