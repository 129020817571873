<div class="h-list-body page-header" [ngClass]="{'collapse': collapsed}">
  <a href="javascript:" class="h-close-text" (click)="collapse()"><i class="feather" [ngClass]="{'icon-chevrons-up': !collapsed, 'icon-chevrons-down': collapsed}"></i></a>
  <span *ngIf="collapsed">Filtros de búsqueda</span>
  <div class="filters row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-1">
          <div class="form-group">
            <label class="label-control">Año</label>
            <ng-select name="year" (ngModelChange)="updateFilters($event, 'year')" [allowClear]="'true'" [ngClass]="'ng-select'" [options]="searchFields.years" [(ngModel)]="filters.year">
            </ng-select>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label class="label-control">Mes</label>
            <ng-select name="month" [allowClear]="'true'" [ngClass]="'ng-select'" [options]="searchFields.months" [(ngModel)]="filters.month">
            </ng-select>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label class="label-control">Provincia</label>
            <ng-select name="province"  (ngModelChange)="excludeFields($event,'province')"  [allowClear]="'true'" [ngClass]="'ng-select'" [options]="searchFields.provinces" [(ngModel)]="filters.province"> </ng-select>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label class="label-control">Municipio</label>
            <ng-select name="province" (ngModelChange)="excludeFields($event,'town')" [allowClear]="'true'" [ngClass]="'ng-select'" [options]="searchFields.towns" [(ngModel)]="filters.town">
            </ng-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="label-control">Centro</label>
            <ng-select name="clinic" (ngModelChange)="excludeFields($event,'clinic')" [allowClear]="'true'" [ngClass]="'ng-select'" [options]="searchFields.clinics" [(ngModel)]="filters.clinic">
            </ng-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="label-control">Médico</label>
            <ng-select name="doctor" (ngModelChange)="excludeFields($event,'doctor')" [allowClear]="'true'" [ngClass]="'ng-select'" [options]="searchFields.doctors" [(ngModel)]="filters.doctor">
            </ng-select>
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label class="label-control">Sexo</label>
            <ng-select name="gender"  [ngClass]="'ng-select'" [options]="searchFields.genders" [(ngModel)]="filters.gender">
            </ng-select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="label-control">Grupo edad</label>
            <ng-select name="age" [ngClass]="'ng-select'" [options]="searchFields.ages" [(ngModel)]="filters.age">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
      <div class="col">
        <div class="form-group" style="float: left">
          <button class="btn btn-primary" (click)="updateQuery()">Aplicar filtros</button>
        </div>
        <div class="form-group">
          <label style="padding: 5px 0px 0px 10px">
            Total de pacientes únicos: {{totalFilter}}
          </label>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
