import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {SearchFieldsService} from '../../../services/search-fields.service';
import {SearchFields} from '../../../model/search-fields';
import {QueryOptions} from "../../../services/query-options";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltersComponent implements OnInit {
  @Output() filtersCollapse = new EventEmitter();
  collapsed = false;
  searchFields: SearchFields = new SearchFields();
  filters: {
    year: string;
    month: string;
    province: string;
    postalCode: string;
    town: string;
    clinic: string;
    doctor: string;
    gender: string;
    age: string;
  };
  public totalFilter = 0;
  constructor(private session: SessionService, private searchFieldsService: SearchFieldsService) {
    this.filters = SessionService.filters;

    this.updateFilters();
    SessionService.newData.subscribe(
      result => {
       this.session.getMedicalInfo().subscribe(
          medicalInfo => {
            this.totalFilter = medicalInfo.totalFilter;
          }
        );
      }
    );
  }

  ngOnInit() {
  }

  updateFilters($event?, field?) {
    if ($event !== 'undefined' && typeof field !== 'undefined' && typeof this.filters[field] !== 'undefined') {
      this.filters[field] = $event;
    }

    this.searchFieldsService.list(new QueryOptions(this.filters)).subscribe(
      result => {
        this.searchFields = result[0];
        this.searchFields.months = [
          {value: '1', label: 'enero', disabled: false},
          {value: '2', label: 'febrero', disabled: false},
          {value: '3', label: 'marzo', disabled: false},
          {value: '4', label: 'abril', disabled: false},
          {value: '5', label: 'mayo', disabled: false},
          {value: '6', label: 'junio', disabled: false},
          {value: '7', label: 'julio', disabled: false},
          {value: '8', label: 'agosto', disabled: false},
          {value: '9', label: 'septiembre', disabled: false},
          {value: '10', label: 'octubre', disabled: false},
          {value: '11', label: 'noviembre', disabled: false},
          {value: '12', label: 'diciembre', disabled: false}
        ];
      }
    );
  }

  updateQuery() {
    // Comenzar a cargar
    SessionService.filters = this.filters;
    this.session.setContentLoading(true);
    this.session.loadMedicalInfo().subscribe(
      result => {
        this.session.setContentLoading(false);
        SessionService.newData.emit(true);
        this.totalFilter = result.totalFilter;
        // Terminar de cargar
        console.log('Carga completa');
      },
      error => {
        this.session.setContentLoading(false);
      }
    );
  }

  collapse() {
    this.collapsed = !this.collapsed;
  }

  excludeFields($event, field: string) {
    // estos campos son exluyentes unos de otros
    switch (field) {
      case 'province':
        this.filters.postalCode = '';
        this.filters.town = '';
        this.filters.doctor = '';
        this.filters.clinic = '';
        // Si el cambio es en provincia, recarga los filtros ya que cargará nuevos pueblos, codigos postales, etc
        this.updateFilters($event, field);
        break;
      case 'town':
        this.filters.postalCode = '';
        this.filters.doctor = '';
        this.filters.clinic = '';
        break;
      case 'doctor':
        this.filters.postalCode = '';
        this.filters.town = '';
        this.filters.clinic = '';
        break;
      case 'clinic':
        this.filters.postalCode = '';
        this.filters.town = '';
        this.filters.doctor = '';
        break;
    }
  }
}
