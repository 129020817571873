import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetRoutingModule } from './widget-routing.module';
import { IndicatorsTableComponent } from './indicators-table/indicators-table.component';

@NgModule({
  declarations: [IndicatorsTableComponent],
  exports: [
    IndicatorsTableComponent
  ],
  imports: [
    CommonModule,
    WidgetRoutingModule
  ]
})
export class WidgetModule { }
