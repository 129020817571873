import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {Measure} from '../../../model/measure';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-prevalence-indicators',
  templateUrl: './prevalence-indicators.component.html',
  styleUrls: ['./prevalence-indicators.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrevalenceIndicatorsComponent implements OnInit {
  measures: Measure[] = [];

  // tslint:disable-next-line:max-line-length
  constructor(private session: SessionService, private sanitizer: DomSanitizer, private http: HttpClient) {
    SessionService.newData.subscribe(
      result => {
        this.refreshInfo();
      }
    );
  }

  ngOnInit() {
    this.session.setContentLoading(true);
    this.session.getMedicalInfo().subscribe(
      medicalInfo => {
        this.refreshInfo();
        this.session.setContentLoading(false);
      },
      error => {
        // TODO - error
        this.session.setContentLoading(false);
      }
    );
  }

  private refreshInfo() {
    this.session.getMedicalInfo().subscribe(
      medicalInfo => {
        this.measures = [
          medicalInfo.diabetesPrevalence,
          medicalInfo.hiddenDiabetes,
          medicalInfo.preDiabetes];
      });
  }
}
