import {Resource} from './resource';

export class User extends Resource {
  public username = '';
  public email = '';
  public enabled = false;
  public roles: string[] = [];
  public firstname = '';
  public lastname = '';
  public locale = '';
  public timezone = '';
  plainPassword = { first: '', second: '' };
}
