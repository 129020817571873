import {ModuleWithProviders, NgModule, Optional, SkipSelf, Inject, EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {from, Observable, Observer, throwError} from 'rxjs';

import { AuthConfig } from './auth.service';
import { AuthService } from './auth.service';
import {User} from '../../model/user';


@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  providers:    [ AuthService ]
})
export class AuthModule {

  constructor(@Inject(AuthService) private authService: AuthService, @Optional() @Inject(AuthModule) @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        {provide: AuthConfig, useValue: config }
      ]
    };
  }
}
