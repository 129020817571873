import {Injectable} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {SearchFields} from '../model/search-fields';
import {FieldsSerializer} from '../serializer/fields.serializer';
import {environment} from '../../environments/environment';
import {ResourceService} from './resource.service';

@Injectable()
export class SearchFieldsService extends ResourceService<SearchFields> {
  fakeData = {
    years: [
      {
        value: '2019',
        label: '2019'
      },
      {
        value: '2020',
        label: '2020'
      }
    ],
    provinces: [
      {
        value: '29',
        label: 'Málaga'
      },
      {
        value: '11',
        label: 'Cádiz'
      },
      {
        value: '21',
        label: 'Huelva'
      },
      {
        value: '14',
        label: 'Córdoba'
      },
      {
        value: '23',
        label: 'Jaén'
      },
      {
        value: '41',
        label: 'Sevilla'
      },
      {
        value: '04',
        label: 'Almería'
      },
      {
        value: '18',
        label: 'Granada'
      }
    ],
    towns: [
      {
        value: '04013',
        label: 'Almería'
      },
      {
        value: '29067',
        label: 'Málaga'
      },
      {
        value: '29069',
        label: 'Marbella'
      },
      {
        value: '41065',
        label: 'Morón de la Frontera'
      },
      {
        value: '41016',
        label: 'Bollullos de la Mitación'
      },
      {
        value: '21005',
        label: 'Almonte'
      },
      {
        value: '21078',
        label: 'Zalamea la Real'
      },
      {
        value: '21030',
        label: 'Chucena'
      },
      {
        value: '21056',
        label: 'Paterna del Campo'
      },
      {
        value: '41023',
        label: 'Cantillana'
      },
      {
        value: '41017',
        label: 'Bormujos'
      },
      {
        value: '11020',
        label: 'Jerez de la Frontera'
      },
      {
        value: '21077',
        label: 'Villarrasa'
      },
      {
        value: '18094',
        label: 'Güejar Sierra'
      },
      {
        value: '18071',
        label: 'Dúrcal'
      },
      {
        value: '14017',
        label: 'La Carlota'
      },
      {
        value: '21061',
        label: 'Rociana del Condado'
      },
      {
        value: '21012',
        label: 'Berrocal'
      },
      {
        value: '41101',
        label: 'Villaverde del Río'
      },
      {
        value: '18013',
        label: 'Alhama de Granada'
      },
      {
        value: '11009',
        label: 'Benaocaz'
      },
      {
        value: '29070',
        label: 'Mijas'
      },
      {
        value: '41072',
        label: 'Pedrera'
      },
      {
        value: '29051',
        label: 'Estepona'
      },
      {
        value: '41005',
        label: 'Alcalá del Río'
      },
      {
        value: '21041',
        label: 'Huelva'
      },
      {
        value: '11004',
        label: 'Algeciras'
      },
      {
        value: '41083',
        label: 'El Ronquillo'
      },
      {
        value: '11007',
        label: 'Barbate'
      },
      {
        value: '41006',
        label: 'Alcolea del Río'
      },
      {
        value: '21069',
        label: 'Santa Olalla del Cala'
      },
      {
        value: '21006',
        label: 'Alosno'
      },
      {
        value: '29007',
        label: 'Alhaurín de la Torre'
      },
      {
        value: '41019',
        label: 'Burguillos'
      },
      {
        value: '41058',
        label: 'Mairena del Alcor'
      },
      {
        value: '21079',
        label: 'Zufre'
      },
      {
        value: '41031',
        label: 'El Castillo de las Guardas'
      },
      {
        value: '41051',
        label: 'Huévar del Aljarafe'
      },
      {
        value: '21062',
        label: 'Rosal de la Frontera'
      },
      {
        value: '21047',
        label: 'Manzanilla'
      },
      {
        value: '41060',
        label: 'Marchena'
      },
      {
        value: '11041',
        label: 'Villamartín'
      },
      {
        value: '14007',
        label: 'Baena'
      },
      {
        value: '21076',
        label: 'Villanueva de los Castillejos'
      },
      {
        value: '21066',
        label: 'San Silvestre de Guzmán'
      },
      {
        value: '41028',
        label: 'Castilleja de Guzmán'
      },
      {
        value: '21016',
        label: 'Cala'
      },
      {
        value: '18907',
        label: 'Valle del Zalabí'
      },
      {
        value: '14051',
        label: 'Pedroche'
      },
      {
        value: '41040',
        label: 'Espartinas'
      },
      {
        value: '41085',
        label: 'Salteras'
      },
      {
        value: '04029',
        label: 'Berja'
      },
      {
        value: '21071',
        label: 'Valdelarco'
      },
      {
        value: '41055',
        label: 'Lora del Río'
      },
      {
        value: '14036',
        label: 'Hornachuelos'
      },
      {
        value: '41048',
        label: 'Guadalcanal'
      },
      {
        value: '41093',
        label: 'Tomares'
      },
      {
        value: '29008',
        label: 'Alhaurín el Grande'
      },
      {
        value: '41099',
        label: 'Villanueva del Río y Minas'
      },
      {
        value: '11022',
        label: 'La Línea de la Concepción'
      },
      {
        value: '11012',
        label: 'Cádiz'
      },
      {
        value: '29080',
        label: 'Pizarra'
      },
      {
        value: '14027',
        label: 'Fernán-Núñez'
      },
      {
        value: '41034',
        label: 'Coria del Río'
      },
      {
        value: '41021',
        label: 'Camas'
      },
      {
        value: '18087',
        label: 'Granada'
      },
      {
        value: '21063',
        label: 'San Bartolomé de la Torre'
      },
      {
        value: '11027',
        label: 'El Puerto de Santa María'
      },
      {
        value: '41038',
        label: 'Dos Hermanas'
      },
      {
        value: '21015',
        label: 'Cabezas Rubias'
      },
      {
        value: '21038',
        label: 'Higuera de la Sierra'
      },
      {
        value: '41087',
        label: 'Sanlúcar la Mayor'
      },
      {
        value: '41078',
        label: 'La Puebla de los Infantes'
      },
      {
        value: '21074',
        label: 'Villalba del Alcor'
      },
      {
        value: '41079',
        label: 'La Puebla del Río'
      },
      {
        value: '18140',
        label: 'Motril'
      },
      {
        value: '41037',
        label: 'Los Corrales'
      },
      {
        value: '41091',
        label: 'Sevilla'
      },
      {
        value: '21046',
        label: 'Lucena del Puerto'
      },
      {
        value: '41096',
        label: 'Valencina de la Concepción'
      },
      {
        value: '41012',
        label: 'Aznalcázar'
      },
      {
        value: '41036',
        label: 'El Coronil'
      },
      {
        value: '41095',
        label: 'Utrera'
      },
      {
        value: '29094',
        label: 'Vélez-Málaga'
      },
      {
        value: '41045',
        label: 'Gerena'
      },
      {
        value: '41081',
        label: 'La Rinconada'
      },
      {
        value: '41082',
        label: 'La Roda de Andalucía'
      },
      {
        value: '21035',
        label: 'Gibraleón'
      },
      {
        value: '41089',
        label: 'Santiponce'
      },
      {
        value: '41033',
        label: 'Constantina'
      },
      {
        value: '18021',
        label: 'Armilla'
      },
      {
        value: '21072',
        label: 'Valverde del Camino'
      },
      {
        value: '21001',
        label: 'Alájar'
      },
      {
        value: '21058',
        label: 'Puebla de Guzmán'
      },
      {
        value: '41067',
        label: 'Olivares'
      },
      {
        value: '21018',
        label: 'El Campillo'
      },
      {
        value: '18177',
        label: 'Sorvilán'
      },
      {
        value: '11029',
        label: 'Puerto Serrano'
      },
      {
        value: '41073',
        label: 'El Pedroso'
      },
      {
        value: '41030',
        label: 'Castilleja del Campo'
      },
      {
        value: '21011',
        label: 'Beas'
      },
      {
        value: '21031',
        label: 'Encinasola'
      },
      {
        value: '11032',
        label: 'Sanlúcar de Barrameda'
      },
      {
        value: '11016',
        label: 'Chipiona'
      },
      {
        value: '21013',
        label: 'Bollullos Par del Condado'
      },
      {
        value: '14013',
        label: 'Cabra'
      },
      {
        value: '23092',
        label: 'Úbeda'
      },
      {
        value: '18088',
        label: 'Guadahortuna'
      },
      {
        value: '21057',
        label: 'Paymogo'
      },
      {
        value: '14054',
        label: 'Pozoblanco'
      },
      {
        value: '41024',
        label: 'Carmona'
      },
      {
        value: '18905',
        label: 'Las Gabias'
      },
      {
        value: '11030',
        label: 'Rota'
      },
      {
        value: '21060',
        label: 'Punta Umbría'
      },
      {
        value: '41002',
        label: 'Alanís'
      },
      {
        value: '41010',
        label: 'Almensilla'
      },
      {
        value: '41014',
        label: 'Badolatosa'
      },
      {
        value: '41027',
        label: 'Castiblanco de los Arroyos'
      },
      {
        value: '11028',
        label: 'Puerto Real'
      },
      {
        value: '21044',
        label: 'Lepe'
      },
      {
        value: '29901',
        label: 'Torremolinos'
      },
      {
        value: '23066',
        label: 'Peal de Becerro'
      },
      {
        value: '41003',
        label: 'Albaida del Aljarafe'
      },
      {
        value: '41056',
        label: 'La Luisiana'
      },
      {
        value: '41050',
        label: 'Herrera'
      },
      {
        value: '21068',
        label: 'Santa Bárbara de Casa'
      },
      {
        value: '21004',
        label: 'Almonaster la Real'
      },
      {
        value: '21037',
        label: 'El Granado'
      },
      {
        value: '41094',
        label: 'Umbrete'
      },
      {
        value: '21025',
        label: 'Cortegana'
      },
      {
        value: '21010',
        label: 'Ayamonte'
      },
      {
        value: '21065',
        label: 'Sanlúcar de Guadiana'
      },
      {
        value: '21055',
        label: 'Palos de la Frontera'
      },
      {
        value: '41077',
        label: 'La Puebla de Cazalla'
      },
      {
        value: '21021',
        label: 'Cartaya'
      },
      {
        value: '41009',
        label: 'Almadén de la Plata'
      },
      {
        value: '21003',
        label: 'El Almendro'
      },
      {
        value: '23061',
        label: 'Mengíbar'
      },
      {
        value: '41020',
        label: 'Las Cabezas de San Juan'
      },
      {
        value: '21017',
        label: 'Calañas'
      },
      {
        value: '11010',
        label: 'Bornos'
      },
      {
        value: '21009',
        label: 'Arroyomolinos de León'
      },
      {
        value: '14049',
        label: 'Palma del Río'
      },
      {
        value: '41080',
        label: 'El Real de la Jara'
      },
      {
        value: '21050',
        label: 'Moguer'
      },
      {
        value: '21054',
        label: 'La Palma del Condado'
      },
      {
        value: '18192',
        label: 'Zafarraya'
      },
      {
        value: '41018',
        label: 'Brenes'
      },
      {
        value: '41039',
        label: 'Écija'
      },
      {
        value: '29055',
        label: 'Fuente de Piedra'
      },
      {
        value: '21032',
        label: 'Escacena del Campo'
      },
      {
        value: '41075',
        label: 'Pilas'
      },
      {
        value: '11014',
        label: 'Conil de la Frontera'
      },
      {
        value: '14019',
        label: 'Castro del Río'
      },
      {
        value: '41001',
        label: 'Aguadulce'
      },
      {
        value: '41022',
        label: 'La Campana'
      },
      {
        value: '41013',
        label: 'Aznalcóllar'
      },
      {
        value: '21029',
        label: 'Cumbres Mayores'
      },
      {
        value: '21007',
        label: 'Aracena'
      },
      {
        value: '11017',
        label: 'Espera'
      },
      {
        value: '21052',
        label: 'Nerva'
      },
      {
        value: '11015',
        label: 'Chiclana de la Frontera'
      },
      {
        value: '41029',
        label: 'Castilleja de la Cuesta'
      },
      {
        value: '41102',
        label: 'El Viso del Alcor'
      },
      {
        value: '23050',
        label: 'Jaén'
      },
      {
        value: '41084',
        label: 'El Rubio'
      },
      {
        value: '21053',
        label: 'Niebla'
      },
      {
        value: '21064',
        label: 'San Juan del Puerto'
      },
      {
        value: '41098',
        label: 'Villanueva del Ariscal'
      },
      {
        value: '21014',
        label: 'Bonares'
      },
      {
        value: '21040',
        label: 'Hinojos'
      },
      {
        value: '21002',
        label: 'Aljaraque'
      },
      {
        value: '21042',
        label: 'Isla Cristina'
      },
      {
        value: '41032',
        label: 'Cazalla de la Sierra'
      },
      {
        value: '29082',
        label: 'Rincón de la Victoria'
      },
      {
        value: '41043',
        label: 'El Garrobo'
      },
      {
        value: '41026',
        label: 'Casariche'
      },
      {
        value: '18127',
        label: 'Maracena'
      },
      {
        value: '04079',
        label: 'Roquetas de Mar'
      },
      {
        value: '04041',
        label: 'Enix'
      },
      {
        value: '41057',
        label: 'El Madroño'
      },
      {
        value: '41004',
        label: 'Alcalá de Guadaíra'
      },
      {
        value: '21020',
        label: 'Cañaveral de León'
      },
      {
        value: '21070',
        label: 'Trigueros'
      },
      {
        value: '41070',
        label: 'Palomares del Río'
      },
      {
        value: '41086',
        label: 'San Juan de Aznalfarache'
      },
      {
        value: '14021',
        label: 'Córdoba'
      },
      {
        value: '29084',
        label: 'Ronda'
      },
      {
        value: '14052',
        label: 'Peñarroya-Pueblonuevo'
      },
      {
        value: '21073',
        label: 'Villablanca'
      },
      {
        value: '41059',
        label: 'Mairena del Aljarafe'
      },
      {
        value: '21043',
        label: 'Jabugo'
      },
      {
        value: '04902',
        label: 'El Ejido'
      },
      {
        value: '21022',
        label: 'Castaño del Robledo'
      },
      {
        value: '41047',
        label: 'Gines'
      },
      {
        value: '21075',
        label: 'Villanueva de las Cruces'
      },
      {
        value: '41025',
        label: 'Carrión de los Céspedes'
      },
      {
        value: '11038',
        label: 'Ubrique'
      },
      {
        value: '14029',
        label: 'Fuente Obejuna'
      },
      {
        value: '21023',
        label: 'El Cerro de Andévalo'
      },
      {
        value: '41052',
        label: 'Lantejuela'
      },
      {
        value: '41049',
        label: 'Guillena'
      },
      {
        value: '41068',
        label: 'Osuna'
      },
      {
        value: '41074',
        label: 'Peñaflor'
      },
      {
        value: '21019',
        label: 'Campofrío'
      },
      {
        value: '21049',
        label: 'Minas de Riotinto'
      },
      {
        value: '41044',
        label: 'Gelves'
      },
      {
        value: '41007',
        label: 'La Algaba'
      },
      {
        value: '29047',
        label: 'Cuevas Bajas'
      },
      {
        value: '41092',
        label: 'Tocina'
      },
      {
        value: '21008',
        label: 'Aroche'
      },
      {
        value: '41902',
        label: 'Isla Mayor'
      }
    ],
    postalCodes: [
      {
        value: '41704',
        label: '41704 (Dos Hermanas)'
      },
      {
        value: '21830',
        label: '21830 (Bonares)'
      },
      {
        value: '21620',
        label: '21620 (Trigueros)'
      },
      {
        value: '41016',
        label: '41016 (Sevilla)'
      },
      {
        value: '18713',
        label: '18713 (Sorvilán)'
      },
      {
        value: '41980',
        label: '41980 (La Algaba)'
      },
      {
        value: '41800',
        label: '41800 (Sanlúcar la Mayor)'
      },
      {
        value: '11002',
        label: '11002 (Cádiz)'
      },
      {
        value: '21005',
        label: '21005 (Huelva)'
      },
      {
        value: '41300',
        label: '41300 (La Rinconada)'
      },
      {
        value: '14740',
        label: '14740 (Hornachuelos)'
      },
      {
        value: '41240',
        label: '41240 (Almadén de la Plata)'
      },
      {
        value: '18128',
        label: '18128 (Zafarraya)'
      },
      {
        value: '14010',
        label: '14010 (Córdoba)'
      },
      {
        value: '41017',
        label: '41017 (Sevilla)'
      },
      {
        value: '41250',
        label: '41250 (El Real de la Jara)'
      },
      {
        value: '41703',
        label: '41703 (Dos Hermanas)'
      },
      {
        value: '29700',
        label: '29700 (Vélez-Málaga)'
      },
      {
        value: '41450',
        label: '41450 (Constantina)'
      },
      {
        value: '41888',
        label: '41888 (El Garrobo)'
      },
      {
        value: '21420',
        label: '21420 (Ayamonte)'
      },
      {
        value: '21200',
        label: '21200 (Aracena)'
      },
      {
        value: '14111',
        label: '14111 (La Carlota)'
      },
      {
        value: '41890',
        label: '41890 (El Castillo de las Guardas)'
      },
      {
        value: '29651',
        label: '29651 (Mijas)'
      },
      {
        value: '21320',
        label: '21320 (El Cerro de Andévalo)'
      },
      {
        value: '41005',
        label: '41005 (Sevilla)'
      },
      {
        value: '41808',
        label: '41808 (Villanueva del Ariscal)'
      },
      {
        value: '21440',
        label: '21440 (Lepe)'
      },
      {
        value: '21409',
        label: '21409 (Ayamonte)'
      },
      {
        value: '41568',
        label: '41568 (El Rubio)'
      },
      {
        value: '21890',
        label: '21890 (Manzanilla)'
      },
      {
        value: '23009',
        label: '23009 (Jaén)'
      },
      {
        value: '21750',
        label: '21750 (Almonte)'
      },
      {
        value: '11007',
        label: '11007 (Cádiz)'
      },
      {
        value: '41702',
        label: '41702 (Dos Hermanas)'
      },
      {
        value: '41006',
        label: '41006 (Sevilla)'
      },
      {
        value: '41479',
        label: '41479 (La Puebla de los Infantes)'
      },
      {
        value: '29006',
        label: '29006 (Málaga)'
      },
      {
        value: '21006',
        label: '21006 (Huelva)'
      },
      {
        value: '41019',
        label: '41019 (Sevilla)'
      },
      {
        value: '29520',
        label: '29520 (Fuente de Piedra)'
      },
      {
        value: '18120',
        label: '18120 (Alhama de Granada)'
      },
      {
        value: '14200',
        label: '14200 (Peñarroya-Pueblonuevo)'
      },
      {
        value: '29140',
        label: '29140 (Málaga)'
      },
      {
        value: '41580',
        label: '41580 (Casariche)'
      },
      {
        value: '41510',
        label: '41510 (Mairena del Alcor)'
      },
      {
        value: '21270',
        label: '21270 (Cala)'
      },
      {
        value: '21870',
        label: '21870 (Escacena del Campo)'
      },
      {
        value: '21810',
        label: '21810 (Palos de la Frontera)'
      },
      {
        value: '04700',
        label: '04700 (El Ejido)'
      },
      {
        value: '11550',
        label: '11550 (Chipiona)'
      },
      {
        value: '21319',
        label: '21319 (Calañas)'
      },
      {
        value: '11402',
        label: '11402 (Jerez de la Frontera)'
      },
      {
        value: '29009',
        label: '29009 (Málaga)'
      },
      {
        value: '11140',
        label: '11140 (Conil de la Frontera)'
      },
      {
        value: '41590',
        label: '41590 (La Roda de Andalucía)'
      },
      {
        value: '41230',
        label: '41230 (Castiblanco de los Arroyos)'
      },
      {
        value: '41840',
        label: '41840 (Pilas)'
      },
      {
        value: '21260',
        label: '21260 (Santa Olalla del Cala)'
      },
      {
        value: '41340',
        label: '41340 (Tocina)'
      },
      {
        value: '21388',
        label: '21388 (Cañaveral de León)'
      },
      {
        value: '41760',
        label: '41760 (El Coronil)'
      },
      {
        value: '41907',
        label: '41907 (Valencina de la Concepción)'
      },
      {
        value: '14940',
        label: '14940 (Cabra)'
      },
      {
        value: '41809',
        label: '41809 (Albaida del Aljarafe)'
      },
      {
        value: '11130',
        label: '11130 (Chiclana de la Frontera)'
      },
      {
        value: '29400',
        label: '29400 (Ronda)'
      },
      {
        value: '29130',
        label: '29130 (Alhaurín de la Torre)'
      },
      {
        value: '21400',
        label: '21400 (Ayamonte)'
      },
      {
        value: '41380',
        label: '41380 (Alanís)'
      },
      {
        value: '41710',
        label: '41710 (Utrera)'
      },
      {
        value: '18100',
        label: '18100 (Armilla)'
      },
      {
        value: '21819',
        label: '21819 (Palos de la Frontera)'
      },
      {
        value: '21720',
        label: '21720 (Rociana del Condado)'
      },
      {
        value: '18560',
        label: '18560 (Guadahortuna)'
      },
      {
        value: '41897',
        label: '41897 (El Madroño)'
      },
      {
        value: '23400',
        label: '23400 (Úbeda)'
      },
      {
        value: '14004',
        label: '14004 (Córdoba)'
      },
      {
        value: '14012',
        label: '14012 (Córdoba)'
      },
      {
        value: '41810',
        label: '41810 (Castilleja del Campo)'
      },
      {
        value: '41130',
        label: '41130 (La Puebla del Río)'
      },
      {
        value: '41012',
        label: '41012 (Sevilla)'
      },
      {
        value: '21292',
        label: '21292 (Castaño del Robledo)'
      },
      {
        value: '21500',
        label: '21500 (Gibraleón)'
      },
      {
        value: '21595',
        label: '21595 (Sanlúcar de Guadiana)'
      },
      {
        value: '11659',
        label: '11659 (Puerto Serrano)'
      },
      {
        value: '11003',
        label: '11003 (Cádiz)'
      },
      {
        value: '21520',
        label: '21520 (Alosno)'
      },
      {
        value: '41657',
        label: '41657 (Los Corrales)'
      },
      {
        value: '21880',
        label: '21880 (Paterna del Campo)'
      },
      {
        value: '14400',
        label: '14400 (Pozoblanco)'
      },
      {
        value: '14299',
        label: '14299 (Fuente Obejuna)'
      },
      {
        value: '41089',
        label: '41089 (Dos Hermanas)'
      },
      {
        value: '41359',
        label: '41359 (Villanueva del Río y Minas)'
      },
      {
        value: '41820',
        label: '41820 (Carrión de los Céspedes)'
      },
      {
        value: '41898',
        label: '41898 (El Madroño)'
      },
      {
        value: '29120',
        label: '29120 (Alhaurín el Grande)'
      },
      {
        value: '21001',
        label: '21001 (Huelva)'
      },
      {
        value: '11612',
        label: '11612 (Benaocaz)'
      },
      {
        value: '41140',
        label: '41140 (Isla Mayor)'
      },
      {
        value: '21850',
        label: '21850 (Villarrasa)'
      },
      {
        value: '21647',
        label: '21647 (Berrocal)'
      },
      {
        value: '21820',
        label: '21820 (Lucena del Puerto)'
      },
      {
        value: '18012',
        label: '18012 (Granada)'
      },
      {
        value: '41566',
        label: '41566 (Pedrera)'
      },
      {
        value: '11207',
        label: '11207 (Algeciras)'
      },
      {
        value: '21450',
        label: '21450 (Cartaya)'
      },
      {
        value: '14013',
        label: '14013 (Córdoba)'
      },
      {
        value: '11500',
        label: '11500 (El Puerto de Santa María)'
      },
      {
        value: '21600',
        label: '21600 (Valverde del Camino)'
      },
      {
        value: '23002',
        label: '23002 (Jaén)'
      },
      {
        value: '21390',
        label: '21390 (Encinasola)'
      },
      {
        value: '41849',
        label: '41849 (Aznalcázar)'
      },
      {
        value: '41309',
        label: '41309 (La Rinconada)'
      },
      {
        value: '41310',
        label: '41310 (Brenes)'
      },
      {
        value: '41209',
        label: '41209 (Alcalá del Río)'
      },
      {
        value: '21639',
        label: '21639 (Beas)'
      },
      {
        value: '21210',
        label: '21210 (Zufre)'
      },
      {
        value: '41219',
        label: '41219 (Guillena)'
      },
      {
        value: '21310',
        label: '21310 (Calañas)'
      },
      {
        value: '41350',
        label: '41350 (Villanueva del Río y Minas)'
      },
      {
        value: '41806',
        label: '41806 (Umbrete)'
      },
      {
        value: '21700',
        label: '21700 (La Palma del Condado)'
      },
      {
        value: '41370',
        label: '41370 (Cazalla de la Sierra)'
      },
      {
        value: '41889',
        label: '41889 (El Castillo de las Guardas)'
      },
      {
        value: '41470',
        label: '41470 (Peñaflor)'
      },
      {
        value: '29601',
        label: '29601 (Marbella)'
      },
      {
        value: '41120',
        label: '41120 (Gelves)'
      },
      {
        value: '21891',
        label: '21891 (Chucena)'
      },
      {
        value: '41100',
        label: '41100 (Coria del Río)'
      },
      {
        value: '41900',
        label: '41900 (Camas)'
      },
      {
        value: '41002',
        label: '41002 (Sevilla)'
      },
      {
        value: '41449',
        label: '41449 (Alcolea del Río)'
      },
      {
        value: '41010',
        label: '41010 (Sevilla)'
      },
      {
        value: '41014',
        label: '41014 (Sevilla)'
      },
      {
        value: '21280',
        label: '21280 (Arroyomolinos de León)'
      },
      {
        value: '21530',
        label: '21530 (Alosno)'
      },
      {
        value: '21649',
        label: '21649 (Almonaster la Real)'
      },
      {
        value: '41218',
        label: '41218 (Guillena)'
      },
      {
        value: '41319',
        label: '41319 (Alcalá del Río)'
      },
      {
        value: '21510',
        label: '21510 (San Bartolomé de la Torre)'
      },
      {
        value: '21380',
        label: '21380 (Cumbres Mayores)'
      },
      {
        value: '41640',
        label: '41640 (Osuna)'
      },
      {
        value: '41003',
        label: '41003 (Sevilla)'
      },
      {
        value: '21840',
        label: '21840 (Niebla)'
      },
      {
        value: '21559',
        label: '21559 (Puebla de Guzmán)'
      },
      {
        value: '41930',
        label: '41930 (Bormujos)'
      },
      {
        value: '41400',
        label: '41400 (Écija)'
      },
      {
        value: '41008',
        label: '41008 (Sevilla)'
      },
      {
        value: '18008',
        label: '18008 (Granada)'
      },
      {
        value: '41927',
        label: '41927 (Mairena del Aljarafe)'
      },
      {
        value: '21110',
        label: '21110 (Aljaraque)'
      },
      {
        value: '21004',
        label: '21004 (Huelva)'
      },
      {
        value: '21640',
        label: '21640 (Zalamea la Real)'
      },
      {
        value: '41899',
        label: '41899 (El Castillo de las Guardas)'
      },
      {
        value: '41339',
        label: '41339 (Carmona)'
      },
      {
        value: '41701',
        label: '41701 (Dos Hermanas)'
      },
      {
        value: '29730',
        label: '29730 (Rincón de la Victoria)'
      },
      {
        value: '21122',
        label: '21122 (Aljaraque)'
      },
      {
        value: '41550',
        label: '41550 (Aguadulce)'
      },
      {
        value: '41430',
        label: '41430 (La Luisiana)'
      },
      {
        value: '21290',
        label: '21290 (Almonaster la Real)'
      },
      {
        value: '21860',
        label: '21860 (Villalba del Alcor)'
      },
      {
        value: '11510',
        label: '11510 (Puerto Real)'
      },
      {
        value: '21630',
        label: '21630 (Beas)'
      },
      {
        value: '41318',
        label: '41318 (Villaverde del Río)'
      },
      {
        value: '11648',
        label: '11648 (Espera)'
      },
      {
        value: '21449',
        label: '21449 (Lepe)'
      },
      {
        value: '41970',
        label: '41970 (Santiponce)'
      },
      {
        value: '11005',
        label: '11005 (Cádiz)'
      },
      {
        value: '21710',
        label: '21710 (Bollullos Par del Condado)'
      },
      {
        value: '41009',
        label: '41009 (Sevilla)'
      },
      {
        value: '21003',
        label: '21003 (Huelva)'
      },
      {
        value: '21668',
        label: '21668 (Campofrío)'
      },
      {
        value: '21459',
        label: '21459 (Cartaya)'
      },
      {
        value: '41909',
        label: '41909 (Salteras)'
      },
      {
        value: '41440',
        label: '41440 (Lora del Río)'
      },
      {
        value: '41020',
        label: '41020 (Sevilla)'
      },
      {
        value: '21130',
        label: '21130 (Moguer)'
      },
      {
        value: '11650',
        label: '11650 (Villamartín)'
      },
      {
        value: '21100',
        label: '21100 (Punta Umbría)'
      },
      {
        value: '41110',
        label: '41110 (Bollullos de la Mitación)'
      },
      {
        value: '21590',
        label: '21590 (Villablanca)'
      },
      {
        value: '21230',
        label: '21230 (Cortegana)'
      },
      {
        value: '41200',
        label: '41200 (Alcalá del Río)'
      },
      {
        value: '14412',
        label: '14412 (Pedroche)'
      },
      {
        value: '41928',
        label: '41928 (Palomares del Río)'
      },
      {
        value: '04740',
        label: '04740 (Roquetas de Mar)'
      },
      {
        value: '41870',
        label: '41870 (Aznalcóllar)'
      },
      {
        value: '04760',
        label: '04760 (Berja)'
      },
      {
        value: '21670',
        label: '21670 (Nerva)'
      },
      {
        value: '29680',
        label: '29680 (Estepona)'
      },
      {
        value: '14005',
        label: '14005 (Córdoba)'
      },
      {
        value: '41320',
        label: '41320 (Cantillana)'
      },
      {
        value: '21650',
        label: '21650 (El Campillo)'
      },
      {
        value: '41620',
        label: '41620 (Marchena)'
      },
      {
        value: '41018',
        label: '41018 (Sevilla)'
      },
      {
        value: '21340',
        label: '21340 (Alájar)'
      },
      {
        value: '21430',
        label: '21430 (Isla Cristina)'
      },
      {
        value: '41940',
        label: '41940 (Tomares)'
      },
      {
        value: '21592',
        label: '21592 (Villanueva de las Cruces)'
      },
      {
        value: '41330',
        label: '41330 (Tocina)'
      },
      {
        value: '29570',
        label: '29570 (Alhaurín el Grande)'
      },
      {
        value: '41570',
        label: '41570 (Badolatosa)'
      },
      {
        value: '41001',
        label: '41001 (Sevilla)'
      },
      {
        value: '21760',
        label: '21760 (Almonte)'
      },
      {
        value: '18650',
        label: '18650 (Dúrcal)'
      },
      {
        value: '21610',
        label: '21610 (San Juan del Puerto)'
      },
      {
        value: '21593',
        label: '21593 (El Almendro)'
      },
      {
        value: '41830',
        label: '41830 (Huévar del Aljarafe)'
      },
      {
        value: '21291',
        label: '21291 (Valdelarco)'
      },
      {
        value: '18200',
        label: '18200 (Maracena)'
      },
      {
        value: '21220',
        label: '21220 (Higuera de la Sierra)'
      },
      {
        value: '21360',
        label: '21360 (Jabugo)'
      },
      {
        value: '21250',
        label: '21250 (Rosal de la Frontera)'
      },
      {
        value: '14850',
        label: '14850 (Baena)'
      },
      {
        value: '41013',
        label: '41013 (Sevilla)'
      },
      {
        value: '41500',
        label: '41500 (Alcalá de Guadaíra)'
      },
      {
        value: '14700',
        label: '14700 (Palma del Río)'
      },
      {
        value: '11405',
        label: '11405 (Jerez de la Frontera)'
      },
      {
        value: '21594',
        label: '21594 (El Granado)'
      },
      {
        value: '41360',
        label: '41360 (El Pedroso)'
      },
      {
        value: '41015',
        label: '41015 (Sevilla)'
      },
      {
        value: '21007',
        label: '21007 (Huelva)'
      },
      {
        value: '21730',
        label: '21730 (Almonte)'
      },
      {
        value: '11520',
        label: '11520 (Rota)'
      },
      {
        value: '29010',
        label: '29010 (Málaga)'
      },
      {
        value: '41530',
        label: '41530 (Morón de la Frontera)'
      },
      {
        value: '18005',
        label: '18005 (Granada)'
      },
      {
        value: '41804',
        label: '41804 (Olivares)'
      },
      {
        value: '41860',
        label: '41860 (Gerena)'
      },
      {
        value: '41730',
        label: '41730 (Las Cabezas de San Juan)'
      },
      {
        value: '21800',
        label: '21800 (Moguer)'
      },
      {
        value: '11300',
        label: '11300 (La Línea de la Concepción)'
      },
      {
        value: '18110',
        label: '18110 (Las Gabias)'
      },
      {
        value: '21002',
        label: '21002 (Huelva)'
      },
      {
        value: '21540',
        label: '21540 (Villanueva de los Castillejos)'
      },
      {
        value: '14520',
        label: '14520 (Fernán-Núñez)'
      },
      {
        value: '18160',
        label: '18160 (Güejar Sierra)'
      },
      {
        value: '41111',
        label: '41111 (Almensilla)'
      },
      {
        value: '14550',
        label: '14550 (Castro del Río)'
      },
      {
        value: '11640',
        label: '11640 (Bornos)'
      },
      {
        value: '21560',
        label: '21560 (Paymogo)'
      },
      {
        value: '23006',
        label: '23006 (Jaén)'
      },
      {
        value: '21120',
        label: '21120 (Aljaraque)'
      },
      {
        value: '41989',
        label: '41989 (La Algaba)'
      },
      {
        value: '21660',
        label: '21660 (Minas de Riotinto)'
      },
      {
        value: '23620',
        label: '23620 (Mengíbar)'
      },
      {
        value: '21580',
        label: '21580 (Cabezas Rubias)'
      },
      {
        value: '41429',
        label: '41429 (La Campana)'
      },
      {
        value: '41908',
        label: '41908 (Castilleja de Guzmán)'
      },
      {
        value: '18511',
        label: '18511 (Valle del Zalabí)'
      },
      {
        value: '29014',
        label: '29014 (Málaga)'
      },
      {
        value: '21550',
        label: '21550 (Puebla de Guzmán)'
      },
      {
        value: '11160',
        label: '11160 (Barbate)'
      },
      {
        value: '04710',
        label: '04710 (El Ejido)'
      },
      {
        value: '41004',
        label: '41004 (Sevilla)'
      },
      {
        value: '41567',
        label: '41567 (Herrera)'
      },
      {
        value: '14006',
        label: '14006 (Córdoba)'
      },
      {
        value: '04007',
        label: '04007 (Almería)'
      },
      {
        value: '21410',
        label: '21410 (Isla Cristina)'
      },
      {
        value: '41410',
        label: '41410 (Carmona)'
      },
      {
        value: '11540',
        label: '11540 (Sanlúcar de Barrameda)'
      },
      {
        value: '41807',
        label: '41807 (Espartinas)'
      },
      {
        value: '41390',
        label: '41390 (Guadalcanal)'
      },
      {
        value: '29220',
        label: '29220 (Cuevas Bajas)'
      },
      {
        value: '41880',
        label: '41880 (El Ronquillo)'
      },
      {
        value: '21300',
        label: '21300 (Calañas)'
      },
      {
        value: '21240',
        label: '21240 (Aroche)'
      },
      {
        value: '29620',
        label: '29620 (Torremolinos)'
      },
      {
        value: '41540',
        label: '41540 (La Puebla de Cazalla)'
      },
      {
        value: '14011',
        label: '14011 (Córdoba)'
      },
      {
        value: '29560',
        label: '29560 (Pizarra)'
      },
      {
        value: '21591',
        label: '21591 (San Silvestre de Guzmán)'
      },
      {
        value: '18720',
        label: '18720 (Motril)'
      },
      {
        value: '41011',
        label: '41011 (Sevilla)'
      },
      {
        value: '23460',
        label: '23460 (Peal de Becerro)'
      },
      {
        value: '41920',
        label: '41920 (San Juan de Aznalfarache)'
      },
      {
        value: '41950',
        label: '41950 (Castilleja de la Cuesta)'
      },
      {
        value: '18002',
        label: '18002 (Granada)'
      },
      {
        value: '21330',
        label: '21330 (Almonaster la Real)'
      },
      {
        value: '41520',
        label: '41520 (El Viso del Alcor)'
      },
      {
        value: '41007',
        label: '41007 (Sevilla)'
      },
      {
        value: '21570',
        label: '21570 (Santa Bárbara de Casa)'
      },
      {
        value: '41630',
        label: '41630 (Lantejuela)'
      },
      {
        value: '41720',
        label: '41720 (Utrera)'
      },
      {
        value: '04002',
        label: '04002 (Enix)'
      },
      {
        value: '41220',
        label: '41220 (Burguillos)'
      },
      {
        value: '41092',
        label: '41092 (Sevilla)'
      },
      {
        value: '14003',
        label: '14003 (Córdoba)'
      },
      {
        value: '11600',
        label: '11600 (Ubrique)'
      },
      {
        value: '41960',
        label: '41960 (Gines)'
      },
      {
        value: '21740',
        label: '21740 (Hinojos)'
      },
      {
        value: '41210',
        label: '41210 (Guillena)'
      }
    ],
    clinics: [
      {
        value: 'Adoratrices ',
        label: 'Adoratrices '
      },
      {
        value: 'Aeropuerto Viejo',
        label: 'Aeropuerto Viejo'
      },
      {
        value: 'Alamillo',
        label: 'Alamillo'
      },
      {
        value: 'Alcalá del Rio',
        label: 'Alcalá del Rio'
      },
      {
        value: 'Alcolea del Rio',
        label: 'Alcolea del Rio'
      },
      {
        value: 'Alcosa Mercedes Navarro',
        label: 'Alcosa Mercedes Navarro'
      },
      {
        value: 'Aljaraque ',
        label: 'Aljaraque '
      },
      {
        value: 'Almadén de la Plata',
        label: 'Almadén de la Plata'
      },
      {
        value: 'Almonte ',
        label: 'Almonte '
      },
      {
        value: 'Alosno ',
        label: 'Alosno '
      },
      {
        value: 'Amate ',
        label: 'Amate '
      },
      {
        value: 'Aracena',
        label: 'Aracena'
      },
      {
        value: 'Arroyomolinos de León',
        label: 'Arroyomolinos de León'
      },
      {
        value: 'Ayamonte ',
        label: 'Ayamonte '
      },
      {
        value: 'Aznalcázar Matrona Dª Isabel Virosta Clemente',
        label: 'Aznalcázar Matrona Dª Isabel Virosta Clemente'
      },
      {
        value: 'Beas ',
        label: 'Beas '
      },
      {
        value: 'Bellavista ',
        label: 'Bellavista '
      },
      {
        value: 'Bollullos Par del Condado',
        label: 'Bollullos Par del Condado'
      },
      {
        value: 'Bonares ',
        label: 'Bonares '
      },
      {
        value: 'Brenes',
        label: 'Brenes'
      },
      {
        value: 'Burguillos',
        label: 'Burguillos'
      },
      {
        value: 'CPD Huelva',
        label: 'CPD Huelva'
      },
      {
        value: 'CPD Lepe',
        label: 'CPD Lepe'
      },
      {
        value: 'Cabezas Rubias ',
        label: 'Cabezas Rubias '
      },
      {
        value: 'Cala',
        label: 'Cala'
      },
      {
        value: 'Cantillana D. Manuel Marroco Quevedo',
        label: 'Cantillana D. Manuel Marroco Quevedo'
      },
      {
        value: 'Carmona Ntra. Sra. de Gracia',
        label: 'Carmona Ntra. Sra. de Gracia'
      },
      {
        value: 'Cartaya ',
        label: 'Cartaya '
      },
      {
        value: 'Castilblanco de los Arroyos',
        label: 'Castilblanco de los Arroyos'
      },
      {
        value: 'Castillo de las Guardas',
        label: 'Castillo de las Guardas'
      },
      {
        value: 'Cazalla de la Sierra',
        label: 'Cazalla de la Sierra'
      },
      {
        value: 'Cisneo Alto-Las Naciones',
        label: 'Cisneo Alto-Las Naciones'
      },
      {
        value: 'Constantina',
        label: 'Constantina'
      },
      {
        value: 'Corrales ',
        label: 'Corrales '
      },
      {
        value: 'Diputacion Provincial',
        label: 'Diputacion Provincial'
      },
      {
        value: 'El Almendro ',
        label: 'El Almendro '
      },
      {
        value: 'El Garrobo',
        label: 'El Garrobo'
      },
      {
        value: 'El Gordillo',
        label: 'El Gordillo'
      },
      {
        value: 'El Granado ',
        label: 'El Granado '
      },
      {
        value: 'El Greco  ',
        label: 'El Greco  '
      },
      {
        value: 'El Madroño',
        label: 'El Madroño'
      },
      {
        value: 'El Mirador',
        label: 'El Mirador'
      },
      {
        value: 'El Pedroso',
        label: 'El Pedroso'
      },
      {
        value: 'El Portil ',
        label: 'El Portil '
      },
      {
        value: 'El Porvenir',
        label: 'El Porvenir'
      },
      {
        value: 'El Priorato',
        label: 'El Priorato'
      },
      {
        value: 'El Rocio ',
        label: 'El Rocio '
      },
      {
        value: 'El Rompido ',
        label: 'El Rompido '
      },
      {
        value: 'El Ronquillo',
        label: 'El Ronquillo'
      },
      {
        value: 'El Torrejon ',
        label: 'El Torrejon '
      },
      {
        value: 'El Viar',
        label: 'El Viar'
      },
      {
        value: 'Escacena del Campo ',
        label: 'Escacena del Campo '
      },
      {
        value: 'Esperanza Macarena',
        label: 'Esperanza Macarena'
      },
      {
        value: 'Esquivel',
        label: 'Esquivel'
      },
      {
        value: 'Gerena',
        label: 'Gerena'
      },
      {
        value: 'Gibraleon ',
        label: 'Gibraleon '
      },
      {
        value: 'Guadajoz',
        label: 'Guadajoz'
      },
      {
        value: 'Guadalcanal',
        label: 'Guadalcanal'
      },
      {
        value: 'Guillena Dr. Correa Charro',
        label: 'Guillena Dr. Correa Charro'
      },
      {
        value: 'Hospital Juan Ramon Jimenez ',
        label: 'Hospital Juan Ramon Jimenez '
      },
      {
        value: 'Huelva ',
        label: 'Huelva '
      },
      {
        value: 'Isla Chica ',
        label: 'Isla Chica '
      },
      {
        value: 'Isla Cristina ',
        label: 'Isla Cristina '
      },
      {
        value: 'La Algaba',
        label: 'La Algaba'
      },
      {
        value: 'La Antilla ',
        label: 'La Antilla '
      },
      {
        value: 'La Campana',
        label: 'La Campana'
      },
      {
        value: 'La Orden ',
        label: 'La Orden '
      },
      {
        value: 'La Palma del Condado ',
        label: 'La Palma del Condado '
      },
      {
        value: 'La Redondela ',
        label: 'La Redondela '
      },
      {
        value: 'La Ribera ',
        label: 'La Ribera '
      },
      {
        value: 'La Rinconada',
        label: 'La Rinconada'
      },
      {
        value: 'Las Pajanosas',
        label: 'Las Pajanosas'
      },
      {
        value: 'Lepe ',
        label: 'Lepe '
      },
      {
        value: 'Lora del Rio Virgen de Setefilla',
        label: 'Lora del Rio Virgen de Setefilla'
      },
      {
        value: 'Los Rosales',
        label: 'Los Rosales'
      },
      {
        value: 'Los Rosales ',
        label: 'Los Rosales '
      },
      {
        value: 'Lucena del Puerto ',
        label: 'Lucena del Puerto '
      },
      {
        value: 'Mairena del Alcor',
        label: 'Mairena del Alcor'
      },
      {
        value: 'Manzanilla ',
        label: 'Manzanilla '
      },
      {
        value: 'Marqués de Paradas',
        label: 'Marqués de Paradas'
      },
      {
        value: 'María Fuensanta Pérez Quirós',
        label: 'María Fuensanta Pérez Quirós'
      },
      {
        value: 'Matalascañas ',
        label: 'Matalascañas '
      },
      {
        value: 'Mazagon ',
        label: 'Mazagon '
      },
      {
        value: 'Minas de Herrerias ',
        label: 'Minas de Herrerias '
      },
      {
        value: 'Moguer ',
        label: 'Moguer '
      },
      {
        value: 'Molino de la Vega ',
        label: 'Molino de la Vega '
      },
      {
        value: 'Nerva ',
        label: 'Nerva '
      },
      {
        value: 'Niebla ',
        label: 'Niebla '
      },
      {
        value: 'Palos de la Frontera ',
        label: 'Palos de la Frontera '
      },
      {
        value: 'Paterna del Campo ',
        label: 'Paterna del Campo '
      },
      {
        value: 'Paymogo ',
        label: 'Paymogo '
      },
      {
        value: 'Pino Montano A',
        label: 'Pino Montano A'
      },
      {
        value: 'Pino Montano B',
        label: 'Pino Montano B'
      },
      {
        value: 'Polígono Norte',
        label: 'Polígono Norte'
      },
      {
        value: 'Pozo del Camino ',
        label: 'Pozo del Camino '
      },
      {
        value: 'Puebla de Guzman ',
        label: 'Puebla de Guzman '
      },
      {
        value: 'Puebla de los Infantes',
        label: 'Puebla de los Infantes'
      },
      {
        value: 'Puerta Este Dr. Pedro Vallina',
        label: 'Puerta Este Dr. Pedro Vallina'
      },
      {
        value: 'Punta Umbria ',
        label: 'Punta Umbria '
      },
      {
        value: 'Punta del Moral ',
        label: 'Punta del Moral '
      },
      {
        value: 'Real de la Jara',
        label: 'Real de la Jara'
      },
      {
        value: 'Rociana del Condado ',
        label: 'Rociana del Condado '
      },
      {
        value: 'Ronda Histórica',
        label: 'Ronda Histórica'
      },
      {
        value: 'San Bartolome de la Torre ',
        label: 'San Bartolome de la Torre '
      },
      {
        value: 'San Ignacio del Viar',
        label: 'San Ignacio del Viar'
      },
      {
        value: 'San Jerónimo',
        label: 'San Jerónimo'
      },
      {
        value: 'San José de la Rinconada-Los Carteros',
        label: 'San José de la Rinconada-Los Carteros'
      },
      {
        value: 'San Juan del Puerto ',
        label: 'San Juan del Puerto '
      },
      {
        value: 'San Silvestre de Guzman ',
        label: 'San Silvestre de Guzman '
      },
      {
        value: 'Sanliºcar de Guadiana ',
        label: 'Sanliºcar de Guadiana '
      },
      {
        value: 'Santa Barbara de Casa ',
        label: 'Santa Barbara de Casa '
      },
      {
        value: 'Santa Olalla de Cala',
        label: 'Santa Olalla de Cala'
      },
      {
        value: 'Tharsis ',
        label: 'Tharsis '
      },
      {
        value: 'Tocina',
        label: 'Tocina'
      },
      {
        value: 'Tomares',
        label: 'Tomares'
      },
      {
        value: 'Torre de la Reina Dr. Higinio Capote Gil',
        label: 'Torre de la Reina Dr. Higinio Capote Gil'
      },
      {
        value: 'Trigueros ',
        label: 'Trigueros '
      },
      {
        value: 'Vacio',
        label: 'Vacio'
      },
      {
        value: 'Valdezorras',
        label: 'Valdezorras'
      },
      {
        value: 'Valverde del Camino ',
        label: 'Valverde del Camino '
      },
      {
        value: 'Villablanca ',
        label: 'Villablanca '
      },
      {
        value: 'Villalba del Alcor ',
        label: 'Villalba del Alcor '
      },
      {
        value: 'Villanueva de las Cruces ',
        label: 'Villanueva de las Cruces '
      },
      {
        value: 'Villanueva de los Castillejos ',
        label: 'Villanueva de los Castillejos '
      },
      {
        value: 'Villanueva del Rio',
        label: 'Villanueva del Rio'
      },
      {
        value: 'Villanueva del Rio Y Minas',
        label: 'Villanueva del Rio Y Minas'
      },
      {
        value: 'Villarrasa ',
        label: 'Villarrasa '
      },
      {
        value: 'Villaverde del Rio',
        label: 'Villaverde del Rio'
      },
      {
        value: 'Zufre',
        label: 'Zufre'
      }
    ],
    genders: [
      {
        value: 'Hombre',
        label: 'Hombre'
      },
      {
        value: 'Mujer',
        label: 'Mujer'
      },
      {
        value: 'Total',
        label: 'Ambos géneros'
      }
    ],
    ages: [
      {
        value: 0,
        label: '0-15 años'
      },
      {
        value: 1,
        label: '16-64 años'
      },
      {
        value: 2,
        label: 'Mayores de 65 años'
      },
      {
        value: 'Total',
        label: 'Todas las edades'
      }
    ]
  };

  constructor(http: HttpClient) {
    super(http, environment.API_URL, 'search_fields', new FieldsSerializer());
  }
}
