import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../model/user';
import {Router} from '@angular/router';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-auth-signin-v2',
  templateUrl: './auth-signin-v2.component.html',
  styleUrls: ['./auth-signin-v2.component.scss']
})
export class AuthSigninV2Component implements OnInit {
  user: User = new User();
  remember = false;
  error = {isError: false, message: ''};

  constructor(private authService: AuthService, private router: Router ) {
  }

  ngOnInit() {
  }

  login() {
    this.error.isError = false;
    this.error.message = '';
    this.authService.login(this.user.username, this.user.plainPassword.first, this.user).subscribe(
      result => {
          this.router.navigate(['main/dashboard-page']);
      },
      error => {
        this.error.isError = true;
        if (error.status === 400) {
          this.error.message = 'Login no válido';
        } else if (typeof error.error === 'object' && error.error.error_description) {
          this.error.message = error.error.error_description;
        } else if (typeof error === 'string') {
          this.error.message = error;
        } else {
          this.error.message = 'undefined_error' + ' (' + error.status + ')';
        }
      }
    );

  }
}
