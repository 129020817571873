
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import {QueryOptions} from './query-options';
import {Resource} from '../model/resource';
import {Serializer} from '../serializer/serializer';


export abstract class ResourceService<T extends Resource> {
  constructor(
    protected httpClient: HttpClient,
    protected url: string,
    protected endpoint: string,
    protected serializer: Serializer) {}

  public create(item: T): Observable<T> {
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}`, this.serializer.toJson(item)).pipe(
        map(data => this.serializer.fromJson(data) as T));
  }

  public update(item: T): Observable<T> {
    return this.httpClient
      .patch<T>(`${this.url}/${this.endpoint}/${item.id}`,
        this.serializer.toJson(item)).pipe(
        map(data => this.serializer.fromJson(data) as T));
  }

  read(id: number): Observable<T> {
    return this.httpClient
      .get(`${this.url}/${this.endpoint}/${id}`).pipe(
        map((data: any) => this.serializer.fromJson(data) as T));
  }

  list(queryOptions?: QueryOptions): Observable<T[]> {
    const params = queryOptions ? '?' + queryOptions.toQueryString() : '';
    return this.httpClient
      .get(`${this.url}/${this.endpoint}${params}`).pipe(
        map((data: any) => this.convertData(data)));
  }

  delete(id: number) {
    return this.httpClient
      .delete(`${this.url}/${this.endpoint}/${id}`);
  }

  protected convertData(data: any): T[] {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return data.map((item: any) => this.serializer.fromJson(item));
  }
}
