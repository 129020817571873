import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SessionService} from '../services/session.service';

@Component({
  selector: 'app-med-geo-data',
  templateUrl: './med-geo-data.component.html',
  styleUrls: ['./med-geo-data.component.scss']
})
export class MedGeoDataComponent implements OnInit {
  contentLoading = false;

  constructor(public session: SessionService) {
  }

  ngOnInit() {
    SessionService.contentLoading.subscribe(
      result => {
        setTimeout(() => {
          this.contentLoading = result;
        }, 0);
      }
    );
  }
}
