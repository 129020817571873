import {Resource} from '../model/resource';

export abstract class Serializer {
  fromJson(json: any): Resource {
    if (typeof json !== 'object') {
      json = {response: json};
    }
    return Object.assign({}, json);
  }

  toJson(resource: Resource): any {
    return JSON.parse(JSON.stringify(resource));
  }
}
