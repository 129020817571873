import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {Measure} from '../../../model/measure';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {MapsService} from '../../../services/maps.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MetabolicControl} from '../../../model/metabolic-control';
import {LipidControl} from '../../../model/lipid-control';
import {AlbuminExcretion} from '../../../model/albumin-excretion';
import {KidneyFunction} from '../../../model/kidney-function';
import {ImpairedKidneyFunction, ImpairedKidneyFunctionStages} from '../../../model/impaired-kidney-function';

@Component({
  selector: 'app-result-indicators',
  templateUrl: './result-indicators.component.html',
  styleUrls: ['./result-indicators.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResultIndicatorsComponent implements OnInit {
  measures: Measure[] = [];
  private indicator = '';
  public chartIndicator = '';
  chartTitle: any = '';

  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private activeRoute: ActivatedRoute, private session: SessionService, private sanitizer: DomSanitizer, private http: HttpClient, private mapsService: MapsService) {
    SessionService.newData.subscribe(
      result => {
        this.refreshInfo();
      }
    );
    this.activeRoute.params.subscribe(routeParams => {
      this.indicator = routeParams.id;
      this.session.setContentLoading(true);
      this.session.getMedicalInfo().subscribe(
          medicalInfo => {
            this.refreshInfo();
            this.session.setContentLoading(false);
          },
        error => {
            // TODO - error
          this.session.setContentLoading(false);
        }
      );
    });


  }

  ngOnInit() {
  }

  private refreshInfo() {
    this.session.getMedicalInfo().subscribe(
      medicalInfo => {
        switch (this.indicator) {
          case 'metabolic-control':
            this.chartTitle = 'Control Metabólico';
            const mc: MetabolicControl = medicalInfo.metabolicControl;
            mc.good.title = 'Buen Control';
            mc.moderate.title = 'Control Moderado';
            mc.bad.title = 'Mal Control';
            this.measures = [
              mc.good,
              mc.moderate,
              mc.bad];
            break;
          case 'lipid-control':
            this.chartTitle = 'Control Lipídico';
            const lc: LipidControl = medicalInfo.lipidControl;
            lc.low.title = 'Riesgo Bajo';
            lc.moderate.title = 'Riesgo Intermedio';
            lc.high.title = 'Riesgo Alto';
            this.measures = [
              lc.low,
              lc.moderate,
              lc.high];
            break;
          case 'albumin-excretion':
            this.chartTitle = 'Excreción Albumina';
            const ae: AlbuminExcretion = medicalInfo.albuminExcretion;
            ae.normal.title = 'Normal';
            ae.moderate.title = 'Moderado';
            ae.severe.title = 'Severo';
            ae.nephropathy.title = 'Nefropatía';
            this.measures = [
              ae.normal,
              ae.moderate,
              ae.severe,
              ae.nephropathy];
            break;
          case 'kidney-function':
            this.chartTitle = 'Funcion Renal (Filtrado G.)';
            const kf: KidneyFunction = medicalInfo.kidneyFunction;
            kf.normal.title = 'Normal';
            kf.moderate.title = 'Moderado';
            kf.low.title = 'Disminuido';

            this.measures = [
              kf.low,
              kf.moderate,
              kf.normal];
            break;
          case 'impaired-kidney-function':
            this.chartTitle = 'Funcion Renal Alterada (Excreción Conservada Albumina)';
            const ikf: ImpairedKidneyFunction = medicalInfo.impairedKidneyFunction;
            ikf.moderate.title = 'Deterioro Moderado';
            ikf.severe.title = 'Deterioro Severo';

            this.measures = [
              ikf.moderate,
              ikf.severe];
            break;
          case 'impaired-kidney-function-stages':
            this.chartTitle = 'Funcion Renal Alterada (Excreción Conservada Albumina)';
            const ikfs: ImpairedKidneyFunctionStages = medicalInfo.impairedKidneyFunctionStages;
            ikfs.stage3A.title = 'Estadio 3A';
            ikfs.stage3B.title = 'Estadio 3B';
            ikfs.stage4.title = 'Estadio 4';
            ikfs.stage5.title = 'Estadio 5';

            this.measures = [
              ikfs.stage3A,
              ikfs.stage3B,
              ikfs.stage4,
              ikfs.stage5];
            break;
        }
      }
    );
  }

  getIndicator() {
    let result = '';
    switch (this.indicator) {
      case 'metabolic-control':
        result = 'cont_metab';
        break;
      case 'lipid-control':
        result = 'cont_lipid';
        break;
      case 'albumin-excretion':
        result = 'exc_album';
        break;
      case 'kidney-function':
        result = 'f_renal';
        break;
      case 'impaired-kidney-function':
        result = 'f_renal_exc';
        break;
      case 'impaired-kidney-function-stages':
        result = 'f_renal_sev_exc';
        break;
    }
    return result;
  }
}
