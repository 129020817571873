export interface QueryBuilder {
  toQueryMap: () => Map<string, string>;
  toQueryString: () => string;
}

export class QueryOptions implements QueryBuilder {
  public offset: number;
  public limit: number;
  private properties: any;

  constructor(props: any) {
    this.offset = 0;
    this.limit = 10000;
    this.properties = {};
    Object.assign(this.properties, props);
  }

  toQueryMap() {
    const queryMap = new Map<string, string>();
    queryMap.set('offset', `${this.offset}`);
    queryMap.set('limit', `${this.limit}`);

    for (var prop in this.properties){
      if (this.properties.hasOwnProperty(prop)) {
        queryMap.set(prop, this.properties[prop]);
      }
    }

    return queryMap;
  }

  toQueryString() {
    let queryString = '';
    this.toQueryMap().forEach((value: string, key: string) => {
      queryString = queryString.concat(`${key}=${value}&`);
    });

    return queryString.substring(0, queryString.length - 1);
  }
}
