<div class="row">
  <!-- order-card start -->
  <div *ngFor="let measure of measures" class="col-md-2 col-xl-2 measure-box">
    <app-card customHeader="true" [cardTitle]="measure.title"  [hidHeader]="false" footerClass="border border-top-0 p-0 overflow-hidden" [isCardFooter]="true">
      <div class="app-card-header" >
        <div class="card-header-left">
          <button type="button" class="btn btn-default" popoverClass="tooltip-help" [placement]="'top'" [popoverTitle]="measure.title" [ngbPopover]="measure.tip" data-original-title="popup on top">
            <i class="feather icon-help-circle"></i>
          </button>
        </div>
        <h5>{{ measure.title }}</h5>
        <div class="card-header-right">
          <button type="button" class="btn btn-icon" (click)="goToIndicators(measure.id)">
            <i class="feather icon-maximize"></i>
          </button>
        </div>
      </div>
      <h4 class="m-0">
        <span [ngbTooltip]="getMeasureToolTipText(measure.id,0)" class="ml-2" [ngClass]="measure.current.indicator == true?'text-c-green':'text-c-red'">
          <i class="feather" [ngClass]="measure.current.indicator == true?'icon-arrow-up':'icon-arrow-down'"></i>
          {{measure.current.percentege | number: '1.2-2'}}%
        </span>
      </h4>
      <div class="app-card-footer">
        <div class="row">
          <div class="col-6">
              {{getMeasureText(measure.id)}}
          </div>
          <div class="col-6">
            Año anterior
          </div>
        </div>
        <div class="row">
          <div [ngbTooltip]="getMeasureToolTipText(measure.id, 1)" class="col-6">
            <span class="ml-2" [ngClass]="measure.percentile.indicator == true?'text-c-green':'text-c-red'"><i class="feather" [ngClass]="measure.percentile.indicator == true?'icon-arrow-up':'icon-arrow-down'"></i>{{measure.percentile.percentege | number: '1.2-2'}}%</span>
          </div>
          <div [ngbTooltip]="getMeasureToolTipText(measure.id, 2)" class="col-6">
            <span class="ml-2" [ngClass]="measure.lastYear.indicator == true?'text-c-green':'text-c-red'"><i class="feather" [ngClass]="measure.lastYear.indicator == true?'icon-arrow-up':'icon-arrow-down'"></i>{{measure.lastYear.percentege | number: '1.2-2'}}%</span>
          </div>
        </div>
      </div>
    </app-card>
  </div>
  <!-- order-card end -->

  <!-- conversion-section start -->
  <div class="row charts-info">
    <div *ngFor="let chart of charts" class="col-lg-4 col-md-12">
      <app-card customHeader="true" [hidHeader]="false" footerClass="border border-top-0 p-0 overflow-hidden" [isCardFooter]="true">
        <div class="app-card-header" >
          <h5>{{ chart.title }}</h5>
          <div class="card-header-right">
            <button type="button" class="btn btn-icon" (click)="goToIndicators(chart.id)">
              <i class="feather icon-maximize"></i>
            </button>
          </div>
        </div>
        <div class="app-card-footer">
          <app-apex-chart [chartID]="chart.id" [chartConfig]="chart.chart"></app-apex-chart>
        </div>
      </app-card>
    </div>
  </div>
  <!-- conversion-section end -->

  <!-- customer overview start -->
  <div class="col-md-6" *ngIf="clinicsChart != null">
    <app-card [hidHeader]="false" customHeader="true" footerClass="border border-top-0 p-0 overflow-hidden" [isCardFooter]="true">
      <div class="app-card-header" >
        <h5>Top 20 Centros de Salud</h5>
        <div class="card-header-right">
          <button type="button" class="btn btn-icon" [routerLink]="['/clinic-indicators']">
            <i class="feather icon-maximize"></i>
          </button>
        </div>
      </div>
      <div class="app-card-footer">
        <app-apex-chart chartID="clinics" [chartConfig]="clinicsChart"></app-apex-chart>
      </div>
    </app-card>
  </div>
  <div class="col-md-6" *ngIf="doctorsChart != null">
    <app-card [hidHeader]="false" customHeader="true" footerClass="border border-top-0 p-0 overflow-hidden" [isCardFooter]="true">
      <div class="app-card-header" >
        <h5>Top 20 Médicos</h5>
        <div class="card-header-right">
          <button type="button" class="btn btn-icon" [routerLink]="['/doctor-indicators']">
            <i class="feather icon-maximize"></i>
          </button>
        </div>
      </div>
      <div class="app-card-footer">
        <app-apex-chart chartID="doctors" [chartConfig]="doctorsChart"></app-apex-chart>
      </div>
    </app-card>
  </div>
  <!-- customer overview start -->
</div>
