import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlInfo} from '../../../model/control-info';
import {Stat} from '../../../model/stat';

@Component({
  selector: 'app-indicators-table',
  templateUrl: './indicators-table.component.html',
  styleUrls: ['./indicators-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IndicatorsTableComponent implements OnInit {
  @Input() controlInfo: ControlInfo[] = [];
  constructor() { }

  ngOnInit() {
  }

  getStringValue(stat: Stat) {
    let result = '';
    result = '<span>' + stat.percentege.toFixed(2) + '% ';
    for (let i = 0; i < stat.quality; i++) {
      result += '<i class="fa fa-star" style="font-size: 0.6em;"></i>';
    }
    result += '<span>';
    return result;
  }
}
