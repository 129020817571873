// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export let environment = {   // THIS IS FOR DEV VALUES
  API_URL: 'https://medgeodata-api.uhu.es/index.php/api/private',
  BASE_URL: 'https://medgeodata-api.uhu.es/index.php',
  OAUTH_DOMAIN: '/oauth/v2/token',
  OAUTH_CLIENT_ID: '1_55znz8s6l3oc044k04gk4k480cs4g80wskkk088s0cs8gc0cc8',
  OAUTH_CLIENT_SECRET: '4ht208zgy48w4cg4ck8o0ccgs8o0c04cc8o004w0cw88ss0c84',
  production: false
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
