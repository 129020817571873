<div>
  <table>
      <tr>
        <td></td>
        <th class="main-title" [colSpan]="5">Básico: Control Proceso</th>
        <th class="main-title" [colSpan]="4">Avanzado: Control Resultados</th>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <th class="sub-title">Control HbA1C (2 o mas HbA1C año)</th>
        <th class="sub-title">Control LDL (1 o más LDL año)</th>
        <th class="sub-title">Control Albumina (1 o más IMAC año)</th>
        <th class="sub-title">Resultado Control Metabólico (%HbA1C < 8)</th>
        <th class="sub-title">Media</th>
        <th class="sub-title">Resultado Control Lípidos (%LDL < 100)</th>
        <th class="sub-title">Resultado Control Albumina (%IMAC < 300)</th>
        <th class="sub-title">Resultado Control FG (%FG > 45)</th>
        <th class="sub-title">Media</th>
        <th class="sub-title">Total Final</th>
      </tr>
      <tr *ngFor="let element of controlInfo">
        <td>{{element.name}}</td>
        <td [innerHTML]="getStringValue(element.basic.hba1cControl)"></td>
        <td [innerHTML]="getStringValue(element.basic.ldlControl)"></td>
        <td [innerHTML]="getStringValue(element.basic.albumControl)"></td>
        <td [innerHTML]="getStringValue(element.basic.metabResult)"></td>
        <td [innerHTML]="getStringValue(element.basic.mean)"></td>
        <td [innerHTML]="getStringValue(element.advanced.lipidResult)"></td>
        <td [innerHTML]="getStringValue(element.advanced.albumResult)"></td>
        <td [innerHTML]="getStringValue(element.advanced.fgResult)"></td>
        <td [innerHTML]="getStringValue(element.advanced.mean)"></td>
        <td [innerHTML]="getStringValue(element.final.mean)"></td>
      </tr>
  </table>
</div>
