import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MedicalInfo} from '../model/medical-info';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {ResourceService} from './resource.service';
import {FieldsSerializer} from '../serializer/fields.serializer';
import {QueryOptions} from './query-options';
import {Resource} from '../model/resource';
import {environment} from '../../environments/environment';

class MapUrl extends Resource {
  url: string;
}

@Injectable()
export class MapsService extends ResourceService<MapUrl> {
  constructor(http: HttpClient, private sanitizer: DomSanitizer) {
    super(http, environment.API_URL, 'map', new FieldsSerializer());
  }

  /**
   * 'diagnostico': 0 (conocida), 1 (oculta), 2 (prediabetes)
   'n_cont_metab': 0 (malo), 1 (bueno)
   'n_exc_album': 0 (malo), 1 (bueno)
   'n_ldl': 0 (malo), 1 (bueno)
   'cont_metab': 0 (bueno), 1 (intermedio), 2 (malo)
   'f_renal': 0 (Det,. Severo), 1 (Det.Moderado), 2 (normal)
   'exc_album': 0 (Normal), 1 (Moderado), 2 (Elevado), 3 (Nefropatía)
   'cont_lipid': 0 (Riesgo bajo), 1 (Riesgo alto), 2 (Riesgo muy alto)
   'f_renal_exc': 0 (Deterioro Moderado), 1 (Deterioro Severo)
   'f_renal_sev_exc': 0 (Estadio 3A), 1 (Estadio 3B), 2 (Estadio 4), 3 (Estadio 5)
   */
  get(filterOptions: QueryOptions): Observable<SafeResourceUrl> {
    return new Observable<SafeResourceUrl> (observer => {
      super.list(filterOptions).subscribe(
        result => {
          observer.next(this.sanitizer.bypassSecurityTrustResourceUrl(result[0].url));
        },
        error => {
          observer.next(error);
        }
      );
    });
  }
}

