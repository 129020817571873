import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { User } from '../model/user';
import { UserSerializer } from '../serializer/user.serializer';
import {ResourceService} from './resource.service';


@Injectable({
  providedIn: 'root'
})
export class UserService extends ResourceService<User> {

  constructor(http: HttpClient) {
    super(http, environment.API_URL, 'users', new UserSerializer());
  }

    me() {
        return this.httpClient.get(this.url + '/user/me');
    }
}
