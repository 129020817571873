import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NavigationItem} from '../theme/layout/admin/navigation/navigation';
import {MedicalInfoService} from '../services/medical-info.service';
import {SessionService} from '../services/session.service';
import {DashAnalyticsModule} from './dashboard/dash-analytics/dash-analytics.module';
import {SharedModule} from '../theme/shared/shared.module';
import {MedGeoDataRoutingModule} from './med-geo-data-routing.module';
import {StarChartComponent} from './pages/star-chart/star-chart.component';
import { PrevalenceIndicatorsComponent } from './pages/prevalence-indicators/prevalence-indicators.component';
import {MapsService} from '../services/maps.service';
import {ProcessIndicatorsComponent} from './pages/process-indicators/process-indicators.component';
import {ResultIndicatorsComponent} from './pages/result-indicators/result-indicators.component';
import { FiltersComponent } from './pages/filters/filters.component';
import {SearchFieldsService} from '../services/search-fields.service';
import {SelectModule} from 'ng-select';
import { ClinicIndicatorsComponent } from './pages/clinic-indicators/clinic-indicators.component';
import { DoctorIndicatorsComponent } from './pages/doctor-indicators/doctor-indicators.component';
import {WidgetModule} from './widget/widget.module';
import {RadarChartService} from '../services/radar-chart.service';
import {MedGeoDataComponent} from './med-geo-data.component';
import {UserService} from '../services/user.service';
import {TokenInterceptorService} from "../auth-signin-v2/auth/token-interceptor.service";
import {MapComponent} from "./pages/map/map.component";

@NgModule({
  declarations: [
    MedGeoDataComponent,
    StarChartComponent,
    PrevalenceIndicatorsComponent,
    ProcessIndicatorsComponent,
    ResultIndicatorsComponent,
    FiltersComponent,
    MapComponent,
    ClinicIndicatorsComponent,
    DoctorIndicatorsComponent
  ],
  imports: [
    CommonModule,
    MedGeoDataRoutingModule,
    SharedModule,
    DashAnalyticsModule,
    HttpClientModule,
    SelectModule,
    WidgetModule
  ],
  providers: [
    NavigationItem,
    UserService,
    MedicalInfoService,
    SearchFieldsService,
    SessionService,
    MapsService,
    RadarChartService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true}
  ],
  exports: [
    FiltersComponent,
    MapComponent
  ]
})
export class MedGeoDataModuleModule { }
