import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {Clinic} from "../../../model/clinic";

@Component({
  selector: 'app-clinic-indicators',
  templateUrl: './clinic-indicators.component.html',
  styleUrls: ['./clinic-indicators.component.scss']
})
export class ClinicIndicatorsComponent implements OnInit {
  public centerControls: Clinic[] = [];

  constructor(private session: SessionService) {
    SessionService.newData.subscribe(
      result => {
        this.refreshInfo();
      }
    );
  }

  ngOnInit() {
    this.session.setContentLoading(true);
    this.session.getMedicalInfo().subscribe(
      result => {
        this.refreshInfo();
        this.session.setContentLoading(false);
      },
      error => {
        // TODO - error
        this.session.setContentLoading(false);
      }
    );
  }

  private refreshInfo() {
    this.session.getMedicalInfo().subscribe(
      result => {
        this.centerControls = result.centerControls;
      }
    );
  }
}
