<app-card [ngClass]="'map'" [cardTitle]="title" [hidHeader]="false"  [isCardFooter]="false" [setLoadCard]="isLoadMap()">
  <div class="row scale-selector">
    <div class="col-2">
      <label>Escala Min:</label>
      <input type="number" [(ngModel)]="scaleMin">
    </div>
    <div class="col-2">
      <label>Escala Max:</label>
      <input type="number" [(ngModel)]="scaleMax">
    </div>
    <div class="col-2">
      <button type="button" id="changeScale" (click)="reloadMap(5000)" class="btn btn-primary">Aplicar escala</button>
    </div>
  </div>
  <iframe (load)="mapLoaded()" width="100%" height="100%" [src]="getIndicatorMap()">
  </iframe>
</app-card>
