<div class="auth-wrapper align-items-stretch" style="background: white">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
        <!--img src="assets/images/auth/auth-logo.png" alt="" class="img-fluid"-->
        <h2 style="text-align: center;margin-bottom: 2em;">MedGeoData</h2>
        <div class="row">
          <div class="col">
            <img src="../../assets/images/auth/uhu.png" style="height: 80px;">
          </div>
          <div class="col">
            <img src="../../assets/images/auth/sas.png" style="height: 80px;">
          </div>
          <div class="col">
            <img src="../../assets/images/auth/ue.png" style="height: 80px;">
          </div>
        </div>
        <div class="login-text">
          Sistema creado por:
            <ul>
              <li>el Grupo de Investigación TIC-198, “Sistemas Inteligentes y Minería de Datos”, del Plan Andaluz de Investigación, Desarrollo e Innovación, en la Escuela Técnica Superior de Ingeniería de la Universidad de Huelva,</li>
              <li>el Hospital Universitario Juan Ramón Jiménez,</li>
              <li>y el Hospital Universitario Virgen Macarena, del Servicio Andaluz de Salud</li>
            </ul>
          En el marco del proyecto de I+D+i Ref. UHU-1261404 del Programa Operativo FEDER Andalucía 2014-2020 (Objetivo 1.2.3 para el Fomento y generación de conocimiento orientado a retos de la sociedad, desarrollo de tecnologías emergentes, en salud, cambio demográfico y bienestar social), en la Estrategia de Innovación de Andalucía 2020 (RIS3), dentro del Plan Andaluz de Investigación, Desarrollo e Innovación (PAIDI 2020).
        </div>
      </div>
    </div>
    <div class="auth-side-form">
      <div class=" auth-content">
        <img src="assets/images/auth/auth-logo-dark.png" alt="" class="img-fluid mb-4 d-block d-xl-none d-lg-none">
        <h3 class="mb-4 f-w-400">Signin</h3>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-mail"></i></span>
          </div>
          <input name="email" [(ngModel)]="user.username" type="email" class="form-control" placeholder="Email address">
        </div>
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="feather icon-lock"></i></span>
          </div>
          <input name="password" [(ngModel)]="user.plainPassword.first" type="password" class="form-control" placeholder="Password">
        </div>
        <div class="form-group text-left mt-2">
          <div class="checkbox checkbox-primary d-inline">
            <input [(ngModel)]="remember" type="checkbox" name="checkbox-p-1" id="checkbox-p-1" checked="">
            <label for="checkbox-p-1" class="cr">Save credentials</label>
          </div>
        </div>
        <div class="form-group error" *ngIf="error.isError">
          <label>{{error.message}}</label>
        </div>
        <button class="btn btn-block btn-primary mb-0" (click)="login()">Signin</button>
        <!--div class="text-center">
          <div class="saprator my-4"><span>OR</span></div>
          <button class="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-facebook-f"></i></button>
          <button class="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-google-plus-g"></i></button>
          <button class="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i class="fab fa-twitter"></i></button>
          <p class="mb-2 text-muted">Forgot password? <a [routerLink]="['/auth/reset-password-v2']" class="f-w-400">Reset</a></p>
          <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup-v2']" class="f-w-400">Signup</a></p>
        </div-->
      </div>
    </div>
  </div>
</div>
