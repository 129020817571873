import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '../auth-signin-v2/auth/auth.service';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    let result = true;
    const requiresLogin = route.data.requiresLogin || false;
    if (requiresLogin && !this.authService.isUserLoggedIn()) {
      result = false;
      this.router.navigate(['login']);
    }
    return result;
  }
}
