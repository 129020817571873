import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {Measure} from '../../../model/measure';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MapsService} from '../../../services/maps.service';

@Component({
  selector: 'app-process-indicators',
  templateUrl: './process-indicators.component.html',
  styleUrls: ['./proccess-indicators.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessIndicatorsComponent implements OnInit {
  measures: Measure[] = [];
  // tslint:disable-next-line:max-line-length
  constructor(private session: SessionService, private sanitizer: DomSanitizer, private http: HttpClient, private mapsService: MapsService) {
    SessionService.newData.subscribe(
      result => {
        this.refreshInfo();
      }
    );
  }

  ngOnInit() {
    this.session.setContentLoading(true);
    this.session.getMedicalInfo().subscribe(
      medicalInfo => {
        this.refreshInfo();
        this.session.setContentLoading(false);
      },
      error => {
        // TODO -error
        this.session.setContentLoading(false);
      }
    );
  }

  private refreshInfo() {
    this.session.getMedicalInfo().subscribe(
      medicalInfo => {
        this.measures = [
          medicalInfo.hba1c,
          medicalInfo.ldl,
          medicalInfo.albuminuria];
      }
    );
  }
}
