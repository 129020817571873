import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'dashboard-page',
        title: 'Dashboard',
        type: 'item',
        url: '/main/dashboard-page',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'prevalence-indicators',
        title: 'Ind. Prevalencia',
        type: 'item',
        url: '/main/prevalence-indicators',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'process-indicators',
        title: 'Ind. Proceso',
        type: 'item',
        url: '/main/process-indicators',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'result-indicators',
        title: 'Ind. Resultados',
        type: 'collapse',
        icon: 'feather icon-menu',
        children: [
          {
            id: 'metabolic-control',
            title: 'Control Metabolico',
            type: 'item',
            url: '/main/result-indicators/metabolic-control'
          },
          {
            id: 'lipid-control',
            title: 'Control Lipidico',
            type: 'item',
            url: '/main/result-indicators/lipid-control'
          },
          {
            id: 'albumin-excretion',
            title: 'Excreción Albuminia',
            type: 'item',
            url: '/main/result-indicators/albumin-excretion'
          },
          {
            id: 'kidney-function',
            title: 'Función Renal',
            type: 'item',
            url: '/main/result-indicators/kidney-function'
          },
          {
            id: 'impaired-kidney-function',
            title: 'F.R. Alterada',
            type: 'item',
            url: '/main/result-indicators/impaired-kidney-function',
          },
          {
            id: 'impaired-kidney-function-stages',
            title: 'F.R. Det. Severo',
            type: 'item',
            url: '/main/result-indicators/impaired-kidney-function-stages',
          }
        ]
      },
      {
        id: 'clinic-indicators',
        title: 'Ind. Centros',
        type: 'item',
        url: '/main/clinic-indicators',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'doctor-indicators',
        title: 'Ind. Médicos',
        type: 'item',
        url: '/main/doctor-indicators',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'disabled-menu',
        title: 'Disabled Menu',
        type: 'item',
        url: 'javascript:',
        classes: 'nav-item disabled',
        icon: 'feather icon-power',
        external: true
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
