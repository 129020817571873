  import {NgModule, Injectable, ModuleWithProviders, Inject, EventEmitter} from '@angular/core';
  import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

  import { throwError } from 'rxjs';
  import { Observable } from 'rxjs';

  import { of } from 'rxjs';
  import { catchError, map, tap } from 'rxjs/operators';
  import {User} from '../../model/user';

  export class AuthConfig {
    public BASE_URL = '';
    public OAUTH_DOMAIN = '';
    public OAUTH_CLIENT_ID = '';
    public OAUTH_CLIENT_SECRET = '';
    public API_URL = '';

    constructor(environment: any) {
      if (environment) {
        this.BASE_URL = environment.BASE_URL || '';
        this.OAUTH_DOMAIN = environment.OAUTH_DOMAIN || '';
        this.OAUTH_CLIENT_ID = environment.OAUTH_CLIENT_ID || '';
        this.OAUTH_CLIENT_SECRET = environment.OAUTH_CLIENT_SECRET || '';
        this.API_URL = environment.API_URL || '';
      }
    }
  }

  /*
    Generated class for the AuthService provider.

    See https://angular.io/guide/dependency-injection for more info on providers
    and Angular DI.
  */
  @Injectable({
    providedIn: 'root'
  })
  export class AuthService {
    private storageKey = '';
    private loggedUser: User = new User();
    private token = '';
    private refreshToken = '';
    private tokenType = '';
    private environment: AuthConfig;
      onLogout : EventEmitter<string> = new EventEmitter<string>();


    private httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json'
      })
    };

    constructor(@Inject(AuthConfig) config: AuthConfig, @Inject(HttpClient) private http: HttpClient) {
      console.log('Auth Service started');
      this.environment = config;
      // Comprobar sesion anterior
      if (localStorage.getItem('AuthModule.storageKey')) {
        this.storageKey = localStorage.getItem('AuthModule.storageKey') || '';
        let userSession = null;
        try {
          userSession = JSON.parse(localStorage.getItem(this.storageKey) || '');
        } catch (e) {
          userSession = null;
          localStorage.removeItem(this.storageKey);
        }
        if (userSession) {
          this.setAuthorizationToken(userSession.data);
          this.loggedUser = userSession.user;
        }
      }
    }


    setAuthorizationToken(data: any){
      data = data||{};
      this.tokenType = data.token_type||'';
      this.token = data.access_token||'';
      this.refreshToken = data.refresh_token||'';
      if(this.tokenType == 'bearer'){
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + data.access_token);
      }
      else{
        this.httpOptions.headers = new HttpHeaders({
            'Content-Type':  'application/json',
          'Accept': 'application/json'
          });
      }
    }

    getAccessToken(username: string, password: string): Observable<any> {
      var url = this.environment.BASE_URL + this.environment.OAUTH_DOMAIN + '?client_id=' + this.environment.OAUTH_CLIENT_ID + '&client_secret=' + this.environment.OAUTH_CLIENT_SECRET + '&grant_type=password&username=' + username + '&password=' + password


      return this.http.get<any>(url).pipe(
        map(data => {
                  this.setAuthorizationToken(data);
                  return data;
              }),
              catchError((error: HttpErrorResponse) => {
              return throwError(error);
            }
              )
      );
    }

    getRefreshToken(): Observable<any> {
      var url = this.environment.BASE_URL + this.environment.OAUTH_DOMAIN + '?client_id=' + this.environment.OAUTH_CLIENT_ID + '&client_secret=' + this.environment.OAUTH_CLIENT_SECRET + '&grant_type=refresh_token&refresh_token=' + this.refreshToken;


      return new Observable(
        (observer => {
          this.http.get<any>(url).subscribe(
            data => {
                this.setAuthorizationToken(data);
                observer.next(data);
              },
            error => {
              this.logout();
              observer.error(error);
            });
        })
      );
    }

    getAuthorizationToken(){
      return this.httpOptions.headers.get('Authorization');
    }


    me(): Observable<User>{
      return this.http.get<User>(this.environment.API_URL+'/user/me');
    }


    logout(){
      delete this.loggedUser;
      localStorage.removeItem(this.storageKey);
      localStorage.removeItem('AuthModule.storageKey');
      // Borrar datos de token
      this.setAuthorizationToken({});
      // Lanzar evento.
      this.onLogout.emit('AuthService: logout');
    }


    private log(message: string) {
      console.log('AuthService: ${message}');
    }

    /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
    private handleError(error: HttpErrorResponse): Observable<any> {
      let msg: string = 'Something bad happened; please try again later.';
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        msg = 'An error occurred:'+ error.error.message;
      } else if(error.error instanceof Object){
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        let errorObject = error.error;
        msg = `Backend returned code ${error.status}, ` + `body was: ${errorObject.error}`;
      }
      console.error(msg);
      // return an observable with a user-facing error message
      return throwError(msg);
    };


    login(username: string, password: string, user: User): Observable<User> {
      return new Observable((observer) => {
        this.getAccessToken(username, password).subscribe(
          data => {
            this.storageKey = username + '_' + btoa(password);
            localStorage.setItem('AuthModule.storageKey', this.storageKey);
            // Obtener los datos del usuario
            this.me().subscribe(
              userMe => {
                userMe.id = userMe.id || 1;
                this.loggedUser = Object.assign(user, userMe);
                // Guardar todo en sesion
                const userSession = {
                  data,
                  user: this.loggedUser
                };
                localStorage.setItem(this.storageKey, JSON.stringify(userSession));
                observer.next(this.loggedUser);
              },
              error => {
                observer.error(error);
              }
            );
          },
          error => {
            observer.error(error);
          }
        );
      });
    }

    isUserLoggedIn() {
      return (!this.loggedUser.id || this.loggedUser.id === 0) ? false : true;
    }

    getLoggedUser(user?: User): User {
      if (user) {
        this.loggedUser = Object.assign(user, this.loggedUser);
      }
      return this.loggedUser;
    }

    updateLoggedUser(user?: User) {
      if (user) {
        this.loggedUser = Object.assign(this.loggedUser, user);
      }
      // User session contiene data y user
      let userSession = null;
      try {
        if (localStorage.getItem(this.storageKey)) {
          userSession = JSON.parse(localStorage.getItem(this.storageKey));
          userSession.user = this.loggedUser;
          localStorage.setItem(this.storageKey, JSON.stringify(userSession));
        }
      } catch (e) {
        userSession = null;
        localStorage.removeItem(this.storageKey);
      }


    }
  }
