import { Component, OnInit } from '@angular/core';
import {SessionService} from "../../../services/session.service";
import {Doctor} from "../../../model/doctor";

@Component({
  selector: 'app-doctor-indicators',
  templateUrl: './doctor-indicators.component.html',
  styleUrls: ['./doctor-indicators.component.scss']
})
export class DoctorIndicatorsComponent implements OnInit {
  public doctorControls: Doctor[] = [];

  constructor(private session: SessionService) {
    SessionService.newData.subscribe(
      result => {
        this.refreshInfo();
      }
    );
  }

  ngOnInit() {
    this.session.setContentLoading(true);
    this.session.getMedicalInfo().subscribe(
      result => {
        this.refreshInfo();
        this.session.setContentLoading(false);
      },
      error => {
        // TODO - error
        this.session.setContentLoading(false);
      }
    );
  }

  private refreshInfo() {
    this.session.getMedicalInfo().subscribe(
      result => {
        this.doctorControls = result.doctorControls;
      }
    );
  }
}
