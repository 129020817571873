import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MedicalInfo} from '../model/medical-info';
import {Observable} from 'rxjs';
import {ResourceService} from './resource.service';
import {environment} from '../../environments/environment';
import {FieldsSerializer} from '../serializer/fields.serializer';

@Injectable()
export class MedicalInfoService extends ResourceService<MedicalInfo> {
  private fakeData = {
  diabetesPrevalence: {
    current: {
      indicator: false,
      percentege: 66.21811045696664
    },
    percentile: {
      indicator: false,
      percentege: 0
    },
    lastYear: {
      indicator: false,
      percentege: 92.95337518515906
    }
  },
  hiddenDiabetes: {
    current: {
      indicator: false,
      percentege: 33.1339750179111
    },
    percentile: {
      indicator: false,
      percentege: 0
    },
    lastYear: {
      indicator: false,
      percentege: 6.334203287014178
    }
  },
  preDiabetes: {
    current: {
      indicator: true,
      percentege: 0.6479145251222628
    },
    percentile: {
      indicator: false,
      percentege: 0
    },
    lastYear: {
      indicator: true,
      percentege: 0.7124215278267616
    }
  },
  hba1c: {
    current: {
      indicator: true,
      percentege: 62.18061244392432
    },
    percentile: {
      indicator: false,
      percentege: 0
    },
    lastYear: {
      indicator: true,
      percentege: 8.567774936061381
    }
  },
  ldl: {
    current: {
      indicator: true,
      percentege: 73.20070216500878
    },
    percentile: {
      indicator: false,
      percentege: 0
    },
    lastYear: {
      indicator: true,
      percentege: 98.35890878090366
    }
  },
  albuminuria: {
    current: {
      indicator: false,
      percentege: 1.0159136118215402
    },
    percentile: {
      indicator: false,
      percentege: 0
    },
    lastYear: {
      indicator: false,
      percentege: 0.838697093817047
    }
  },
  metabolicControl: {
    bad: {
      current: {
        indicator: false,
        percentege: 99.19234483364059
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    moderate: {
      current: {
        indicator: true,
        percentege: 0.3335966991484505
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    good: {
      current: {
        indicator: true,
        percentege: 0.47405846721095596
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    }
  },
  kidneyFunction: {
    low: {
      current: {
        indicator: false,
        percentege: 11.484211484211485
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    moderate: {
      current: {
        indicator: false,
        percentege: 14.223314223314224
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    normal: {
      current: {
        indicator: true,
        percentege: 74.2924742924743
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    }
  },
  albuminExcretion: {
    nephropathy: {
      current: {
        indicator: false,
        percentege: 0.6944444444444444
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    severe: {
      current: {
        indicator: true,
        percentege: 2.7777777777777777
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    moderate: {
      current: {
        indicator: false,
        percentege: 24.305555555555554
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    normal: {
      current: {
        indicator: true,
        percentege: 72.22222222222221
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    }
  },
  lipidControl: {
    high: {
      current: {
        indicator: false,
        percentege: 4.243008678881389
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    moderate: {
      current: {
        indicator: true,
        percentege: 5.8341369334619095
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    low: {
      current: {
        indicator: false,
        percentege: 89.9228543876567
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    }
  },
  impairedKidneyFunction: {
    moderate: {
      current: {
        indicator: false,
        percentege: 69.23076923076923
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    severe: {
      current: {
        indicator: true,
        percentege: 30.76923076923077
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    }
    },
  impairedKidneyFunctionStages: {
    stage3A: {
      current: {
        indicator: false,
        percentege: 0
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    stage3B: {
      current: {
        indicator: false,
        percentege: 0
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    stage4: {
      current: {
        indicator: false,
        percentege: 0
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    },
    stage5: {
      current: {
        indicator: false,
        percentege: 0
      },
      percentile: {
        indicator: false,
        percentege: 0
      },
      lastYear: {
        indicator: false,
        percentege: 0
      }
    }
  },
  'centerControls': [
    {
      'name': 'Bonares ',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 5
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 3
        },
        'metabResult': {
          'percentege': 0,
          'quality': 1
        },
        'mean': {
          'percentege': 3.25,
          'quality': 3
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 5
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.666666666666667,
          'quality': 4
        }
      },
      'final': {
        'mean': {
          'percentege': 3.857142857142857,
          'quality': 3
        }
      }
    },
    {
      'name': 'El Viar',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 4
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 4.25,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 1
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.3333333333333335,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.857142857142857,
          'quality': 3
        }
      }
    },
    {
      'name': 'Cala',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.25,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 2
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.3333333333333335,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.857142857142857,
          'quality': 3
        }
      }
    },
    {
      'name': 'San Ignacio del Viar',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 2
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 5
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.25,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 1
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.3333333333333335,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.857142857142857,
          'quality': 3
        }
      }
    },
    {
      'name': 'Castillo de las Guardas',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 5
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 4.25,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 2
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.3333333333333335,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.857142857142857,
          'quality': 3
        }
      }
    },
    {
      'name': 'La Algaba',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 5
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.5,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 3
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 3
        },
        'mean': {
          'percentege': 3.0,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.857142857142857,
          'quality': 3
        }
      }
    },
    {
      'name': 'Pino Montano B',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 2
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 3
        },
        'albumResult': {
          'percentege': 0,
          'quality': 2
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.3333333333333335,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.7142857142857144,
          'quality': 3
        }
      }
    },
    {
      'name': 'Villaverde del Rio',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 3
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.75,
          'quality': 3
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 3
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.6666666666666665,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.7142857142857144,
          'quality': 3
        }
      }
    },
    {
      'name': 'Brenes',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 2
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 4
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.75,
          'quality': 3
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 3
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.6666666666666665,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.7142857142857144,
          'quality': 3
        }
      }
    },
    {
      'name': 'Guadajoz',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 1
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 5
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 2
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.3333333333333335,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.7142857142857144,
          'quality': 3
        }
      }
    },
    {
      'name': 'Mazagon ',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 5
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 5
        },
        'albumControl': {
          'percentege': 0,
          'quality': 3
        },
        'metabResult': {
          'percentege': 0,
          'quality': 1
        },
        'mean': {
          'percentege': 3.5,
          'quality': 3
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 3
        },
        'albumResult': {
          'percentege': 0,
          'quality': 0
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'final': {
        'mean': {
          'percentege': 3.6666666666666665,
          'quality': 3
        }
      }
    },
    {
      'name': 'Carmona Ntra. Sra. de Gracia',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 2
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 1
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.0,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.5714285714285716,
          'quality': 3
        }
      }
    },
    {
      'name': 'San Jer\u00f3nimo',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 2
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 4
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.75,
          'quality': 3
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 2
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.3333333333333335,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.5714285714285716,
          'quality': 3
        }
      }
    },
    {
      'name': 'El Ronquillo',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 3
        },
        'albumControl': {
          'percentege': 0,
          'quality': 4
        },
        'metabResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.5,
          'quality': 3
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 4
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 3.6666666666666665,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.5714285714285716,
          'quality': 3
        }
      }
    },
    {
      'name': 'Cisneo Alto-Las Naciones',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 5
        },
        'albumControl': {
          'percentege': 0,
          'quality': 4
        },
        'metabResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 1
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 3.0,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.5714285714285716,
          'quality': 3
        }
      }
    },
    {
      'name': 'Esperanza Macarena',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 3
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 3
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 3
        },
        'mean': {
          'percentege': 3.0,
          'quality': 3
        }
      },
      'final': {
        'mean': {
          'percentege': 3.5714285714285716,
          'quality': 3
        }
      }
    },
    {
      'name': 'Molino de la Vega ',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 5
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 3
        },
        'metabResult': {
          'percentege': 0,
          'quality': 1
        },
        'mean': {
          'percentege': 3.25,
          'quality': 3
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 5
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 3
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'final': {
        'mean': {
          'percentege': 3.5714285714285716,
          'quality': 3
        }
      }
    },
    {
      'name': 'Constantina',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 1
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 3
        },
        'metabResult': {
          'percentege': 0,
          'quality': 3
        },
        'mean': {
          'percentege': 2.75,
          'quality': 2
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 5
        },
        'albumResult': {
          'percentege': 0,
          'quality': 4
        },
        'fgResult': {
          'percentege': 0,
          'quality': 5
        },
        'mean': {
          'percentege': 4.666666666666667,
          'quality': 4
        }
      },
      'final': {
        'mean': {
          'percentege': 3.5714285714285716,
          'quality': 3
        }
      }
    },
    {
      'name': 'Villanueva del Rio Y Minas',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 4
        },
        'albumControl': {
          'percentege': 0,
          'quality': 5
        },
        'metabResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 3
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 2
        },
        'mean': {
          'percentege': 2.6666666666666665,
          'quality': 2
        }
      },
      'final': {
        'mean': {
          'percentege': 3.4285714285714284,
          'quality': 3
        }
      }
    },
    {
      'name': 'Cantillana D. Manuel Marroco Quevedo',
      'basic': {
        'hba1cControl': {
          'percentege': 0,
          'quality': 3
        },
        'ldlControl': {
          'percentege': 0,
          'quality': 5
        },
        'albumControl': {
          'percentege': 0,
          'quality': 4
        },
        'metabResult': {
          'percentege': 0,
          'quality': 4
        },
        'mean': {
          'percentege': 4.0,
          'quality': 4
        }
      },
      'advanced': {
        'lipidResult': {
          'percentege': 0,
          'quality': 2
        },
        'albumResult': {
          'percentege': 0,
          'quality': 3
        },
        'fgResult': {
          'percentege': 0,
          'quality': 3
        },
        'mean': {
          'percentege': 2.6666666666666665,
          'quality': 2
        }
      },
      'final': {
        'mean': {
          'percentege': 3.4285714285714284,
          'quality': 3
        }
      }
    }
  ],
    'doctorControls': [
      {
        'name': 'Medico 1',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 5
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 3
          },
          'metabResult': {
            'percentege': 0,
            'quality': 1
          },
          'mean': {
            'percentege': 3.25,
            'quality': 3
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 5
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.666666666666667,
            'quality': 4
          }
        },
        'final': {
          'mean': {
            'percentege': 3.857142857142857,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 2',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 4
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 4.25,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 1
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.3333333333333335,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.857142857142857,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 3',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.25,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 2
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.3333333333333335,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.857142857142857,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 4',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 2
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 5
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.25,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 1
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.3333333333333335,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.857142857142857,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 5',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 5
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 4.25,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 2
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.3333333333333335,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.857142857142857,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 6',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 5
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.5,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 3
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 3
          },
          'mean': {
            'percentege': 3.0,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.857142857142857,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 7',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 2
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 3
          },
          'albumResult': {
            'percentege': 0,
            'quality': 2
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.3333333333333335,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.7142857142857144,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 8',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 3
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.75,
            'quality': 3
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 3
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.6666666666666665,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.7142857142857144,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 9',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 2
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 4
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.75,
            'quality': 3
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 3
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.6666666666666665,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.7142857142857144,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 10',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 1
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 5
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 2
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.3333333333333335,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.7142857142857144,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 11',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 5
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 5
          },
          'albumControl': {
            'percentege': 0,
            'quality': 3
          },
          'metabResult': {
            'percentege': 0,
            'quality': 1
          },
          'mean': {
            'percentege': 3.5,
            'quality': 3
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 3
          },
          'albumResult': {
            'percentege': 0,
            'quality': 0
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'final': {
          'mean': {
            'percentege': 3.6666666666666665,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 12',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 2
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 1
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.0,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.5714285714285716,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 13',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 2
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 4
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.75,
            'quality': 3
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 2
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.3333333333333335,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.5714285714285716,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 14',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 3
          },
          'albumControl': {
            'percentege': 0,
            'quality': 4
          },
          'metabResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.5,
            'quality': 3
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 4
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 3.6666666666666665,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.5714285714285716,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 15',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 5
          },
          'albumControl': {
            'percentege': 0,
            'quality': 4
          },
          'metabResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 1
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 3.0,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.5714285714285716,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 16',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 3
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 3
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 3
          },
          'mean': {
            'percentege': 3.0,
            'quality': 3
          }
        },
        'final': {
          'mean': {
            'percentege': 3.5714285714285716,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 17',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 5
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 3
          },
          'metabResult': {
            'percentege': 0,
            'quality': 1
          },
          'mean': {
            'percentege': 3.25,
            'quality': 3
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 5
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 3
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'final': {
          'mean': {
            'percentege': 3.5714285714285716,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 18',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 1
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 3
          },
          'metabResult': {
            'percentege': 0,
            'quality': 3
          },
          'mean': {
            'percentege': 2.75,
            'quality': 2
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 5
          },
          'albumResult': {
            'percentege': 0,
            'quality': 4
          },
          'fgResult': {
            'percentege': 0,
            'quality': 5
          },
          'mean': {
            'percentege': 4.666666666666667,
            'quality': 4
          }
        },
        'final': {
          'mean': {
            'percentege': 3.5714285714285716,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 19',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 4
          },
          'albumControl': {
            'percentege': 0,
            'quality': 5
          },
          'metabResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 3
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 2
          },
          'mean': {
            'percentege': 2.6666666666666665,
            'quality': 2
          }
        },
        'final': {
          'mean': {
            'percentege': 3.4285714285714284,
            'quality': 3
          }
        }
      },
      {
        'name': 'Medico 20',
        'basic': {
          'hba1cControl': {
            'percentege': 0,
            'quality': 3
          },
          'ldlControl': {
            'percentege': 0,
            'quality': 5
          },
          'albumControl': {
            'percentege': 0,
            'quality': 4
          },
          'metabResult': {
            'percentege': 0,
            'quality': 4
          },
          'mean': {
            'percentege': 4.0,
            'quality': 4
          }
        },
        'advanced': {
          'lipidResult': {
            'percentege': 0,
            'quality': 2
          },
          'albumResult': {
            'percentege': 0,
            'quality': 3
          },
          'fgResult': {
            'percentege': 0,
            'quality': 3
          },
          'mean': {
            'percentege': 2.6666666666666665,
            'quality': 2
          }
        },
        'final': {
          'mean': {
            'percentege': 3.4285714285714284,
            'quality': 3
          }
        }
      }
    ]
};
  constructor(http: HttpClient) {
    super(http, environment.API_URL, 'medical_info', new FieldsSerializer());
  }
}
