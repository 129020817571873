import {IOption} from 'ng-select/lib/option.interface';
import {Resource} from './resource';

export class SearchField implements IOption {
  value: string;
  disabled: boolean;
  label: string;
}
export class SearchFields extends Resource {
  years: SearchField[] = [];
  provinces: SearchField[] = [];
  towns: SearchField[] = [];
  postalCodes: SearchField[] = [];
  clinics: SearchField[] = [];
  doctors: SearchField[] = [];
  ages: SearchFields[] = [];
  genders: SearchFields[] = [];
  months: SearchField[] = [];

  constructor() {
    super();
  }
}
