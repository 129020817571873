import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MedicalInfo} from '../model/medical-info';
import {Observable} from 'rxjs';
import {ResourceService} from './resource.service';
import {environment} from '../../environments/environment';
import {FieldsSerializer} from '../serializer/fields.serializer';
import {RadarChartInfo} from '../model/radar-chart-info';
import {QueryOptions} from "./query-options";

@Injectable()
export class RadarChartService extends ResourceService<RadarChartInfo> {
  fakeData = {id: 0, labels: [], series: [
    {
      data: [
        {
          x: 1485907200000,
          y: 0.21054819513622122
        },
        {
          x: 1488326400000,
          y: 0.3983864386946782
        },
        {
          x: 1491004800000,
          y: 0.6337616147930315
        },
        {
          x: 1493596800000,
          y: 0.7884972170686457
        },
        {
          x: 1496275200000,
          y: 0.9967359256037382
        },
        {
          x: 1498867200000,
          y: 1.1976612599019238
        },
        {
          x: 1501545600000,
          y: 1.314675247692438
        },
        {
          x: 1504224000000,
          y: 1.411673684939838
        },
        {
          x: 1506816000000,
          y: 1.548895680490227
        },
        {
          x: 1509494400000,
          y: 1.7122918575200732
        },
        {
          x: 1512086400000,
          y: 1.9128322773847375
        },
        {
          x: 1514764800000,
          y: 2.0095195883837897
        },
        {
          x: 1517443200000,
          y: 2.10086138785578
        },
        {
          x: 1519862400000,
          y: 2.173896286589714
        },
        {
          x: 1522540800000,
          y: 2.262347522787274
        },
        {
          x: 1525132800000,
          y: 2.351184167421425
        },
        {
          x: 1527811200000,
          y: 2.4458019386044363
        },
        {
          x: 1530403200000,
          y: 2.5454300194631263
        },
        {
          x: 1533081600000,
          y: 2.646021621413293
        },
        {
          x: 1535760000000,
          y: 2.734280153392558
        },
        {
          x: 1538352000000,
          y: 2.8815061761701966
        },
        {
          x: 1541030400000,
          y: 3.064960591987359
        },
        {
          x: 1543622400000,
          y: 3.2499566415508836
        },
        {
          x: 1546300800000,
          y: 3.3704407499442235
        },
        {
          x: 1548979200000,
          y: 3.1384873406522393
        },
        {
          x: 1551398400000,
          y: 2.911919250978974
        },
        {
          x: 1554076800000,
          y: 2.7190094089227053
        },
        {
          x: 1556668800000,
          y: 2.5666817968503572
        },
        {
          x: 1559347200000,
          y: 2.599955378780302
        },
        {
          x: 1561939200000,
          y: 2.680542840217567
        },
        {
          x: 1564617600000,
          y: 2.811136840971512
        },
        {
          x: 1567296000000,
          y: 2.904418270081472
        },
        {
          x: 1569888000000,
          y: 3.073671172384081
        },
        {
          x: 1572566400000,
          y: 3.330627851334405
        },
        {
          x: 1575158400000,
          y: 3.266965680127401
        },
        {
          x: 1577836800000,
          y: 3.2280069749171245
        },
        {
          x: 1580515200000,
          y: 3.2280069749171245
        },
        {
          x: 1583020800000,
          y: 3.2280069749171245
        },
        {
          x: 1585699200000,
          y: 3.2280069749171245
        },
        {
          x: 1588291200000,
          y: 3.2280069749171245
        },
        {
          x: 1590969600000,
          y: 3.2280069749171245
        },
        {
          x: 1593561600000,
          y: 3.2280069749171245
        },
        {
          x: 1596240000000,
          y: 3.2280069749171245
        },
        {
          x: 1598918400000,
          y: 3.2280069749171245
        },
        {
          x: 1601510400000,
          y: 3.2280069749171245
        },
        {
          x: 1604188800000,
          y: 3.2280069749171245
        },
        {
          x: 1606780800000,
          y: 3.2280069749171245
        }
      ],
      name: 'Diabetes Oculta'
    },
{
  data: [
    {
      x: 1548979200000,
      y: 0.5039120500373127
    },
    {
      x: 1551398400000,
      y: 0.9958994637760322
    },
    {
      x: 1554076800000,
      y: 1.5350084241785464
    },
    {
      x: 1556668800000,
      y: 1.9798742912534715
    },
    {
      x: 1559347200000,
      y: 2.534369879138041
    },
    {
      x: 1561939200000,
      y: 2.966541778540271
    },
    {
      x: 1564617600000,
      y: 3.234846095258611
    },
    {
      x: 1567296000000,
      y: 3.3817883877122394
    },
    {
      x: 1569888000000,
      y: 3.575082895455559
    },
    {
      x: 1572566400000,
      y: 3.8229999307601763
    },
    {
      x: 1575158400000,
      y: 3.999753813960287
    },
    {
      x: 1577836800000,
      y: 4.074386341426026
    },
    {
      x: 1580515200000,
      y: 4.074386341426026
    },
    {
      x: 1583020800000,
      y: 4.074386341426026
    },
    {
      x: 1585699200000,
      y: 4.074386341426026
    },
    {
      x: 1588291200000,
      y: 4.074386341426026
    },
    {
      x: 1590969600000,
      y: 4.074386341426026
    },
    {
      x: 1593561600000,
      y: 4.074386341426026
    },
    {
      x: 1596240000000,
      y: 4.074386341426026
    },
    {
      x: 1598918400000,
      y: 4.074386341426026
    },
    {
      x: 1601510400000,
      y: 4.074386341426026
    },
    {
      x: 1604188800000,
      y: 4.074386341426026
    },
    {
      x: 1606780800000,
      y: 4.074386341426026
    }
  ],
  name: 'Diabetes Conocida'
},
{
  data: [
  {
    x: 1485907200000,
    y: 0.6029684601113172
  },
  {
    x: 1488326400000,
    y: 1.1607094742838668
  },
  {
    x: 1491004800000,
    y: 1.8087129231171912
  },
  {
    x: 1493596800000,
    y: 2.2600250964210655
  },
  {
    x: 1496275200000,
    y: 2.8235398270964365
  },
  {
    x: 1498867200000,
    y: 3.309109384983718
  },
  {
    x: 1501545600000,
    y: 3.619350418401989
  },
  {
    x: 1504224000000,
    y: 3.8614615970869672
  },
  {
    x: 1506816000000,
    y: 4.18017074804271
  },
  {
    x: 1509494400000,
    y: 4.542182772769613
  },
  {
    x: 1512086400000,
    y: 4.952501558903456
  },
  {
    x: 1514764800000,
    y: 5.1667855009346155
  },
  {
    x: 1517443200000,
    y: 5.65028038463762
  },
  {
    x: 1519862400000,
    y: 6.118166226658701
  },
  {
    x: 1522540800000,
    y: 6.57044302699786
  },
  {
    x: 1525132800000,
    y: 7.061453375214384
  },
  {
    x: 1527811200000,
    y: 7.548416934846704
  },
  {
    x: 1530403200000,
    y: 7.96774131385736
  },
  {
    x: 1533081600000,
    y: 8.290906287938643
  },
  {
    x: 1535760000000,
    y: 8.541229067504288
  },
  {
    x: 1538352000000,
    y: 8.846087140847512
  },
  {
    x: 1541030400000,
    y: 9.216271944192858
  },
  {
    x: 1543622400000,
    y: 9.53616094656312
  },
  {
    x: 1546300800000,
    y: 9.735503873583468
  },
  {
    x: 1548979200000,
    y: 9.731657216712955
  },
  {
    x: 1551398400000,
    y: 9.74973650400437
  },
  {
    x: 1554076800000,
    y: 9.712616265203913
  },
  {
    x: 1556668800000,
    y: 9.666456382757744
  },
  {
    x: 1559347200000,
    y: 9.473738873545003
  },
  {
    x: 1561939200000,
    y: 9.301600978589509
  },
  {
    x: 1564617600000,
    y: 9.370840802258757
  },
  {
    x: 1567296000000,
    y: 9.449120269573713
  },
  {
    x: 1569888000000,
    y: 9.536247047690852
  },
  {
    x: 1572566400000,
    y: 9.615103513536386
  },
  {
    x: 1575158400000,
    y: 9.671072371002362
  },
  {
    x: 1577836800000,
    y: 9.676164562055686
  },
  {
    x: 1580515200000,
    y: 9.676164562055686
  },
  {
    x: 1583020800000,
    y: 9.676164562055686
  },
  {
    x: 1585699200000,
    y: 9.676164562055686
  },
  {
    x: 1588291200000,
    y: 9.676164562055686
  },
  {
    x: 1590969600000,
    y: 9.676164562055686
  },
  {
    x: 1593561600000,
    y: 9.676164562055686
  },
  {
    x: 1596240000000,
    y: 9.676164562055686
  },
  {
    x: 1598918400000,
    y: 9.676164562055686
  },
  {
    x: 1601510400000,
    y: 9.676164562055686
  },
  {
    x: 1604188800000,
    y: 9.676164562055686
  },
  {
    x: 1606780800000,
    y: 9.676164562055686
  }
],
  name: 'Prediabetes'
}
]};
  constructor(http: HttpClient) {
    super(http, environment.API_URL, 'radar_chart', new FieldsSerializer());
  }
/*
  list(queryOptions?: QueryOptions): Observable<RadarChartInfo[]> {
    return new Observable(observer => {
      observer.next([this.fakeData]);
    });
  }
/**/
}
