<div id="http-loader" *ngIf="isSpinnerVisible">
    <div class="loader-bg" [style.background-color]='backgroundColor'>
        <!-- material-line -->
        <div class="sk-line-material" [class.colored]="!spinnerBgColor" *ngIf="spinner === Spinkit.skLine">
            <div class="sk-child sk-bounce1" [style.background-color]='spinnerBgColor'></div>
        </div>
        <div class="sk-cube-grid" *ngIf="spinner === Spinkit.skCubeGrid">
          <div class="sk-cube sk-cube1"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube2"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube3"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube4"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube5"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube6"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube7"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube8"  [style.background-color]='spinnerBgColor'></div>
          <div class="sk-cube sk-cube9"  [style.background-color]='spinnerBgColor'></div>
        </div>
    </div>
</div>
