import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {SearchFieldsService} from '../../../services/search-fields.service';
import {MapsService} from '../../../services/maps.service';
import {SafeHtml, SafeResourceUrl} from '@angular/platform-browser';
import loader from "@angular-devkit/build-angular/src/angular-cli-files/plugins/single-test-transform";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {
  @Input()
  title = '';
  @Input()
  value = 0;
  url: SafeResourceUrl = '';
  status = false;
  scaleMin = 0;
  scaleMax = 0;
  private loaderPostTime = 12000;
  constructor(private session: SessionService, private searchFieldsService: SearchFieldsService, private mapsService: MapsService) {
  }

  ngOnInit() {
    this.reloadMap(12000);
  }

  getIndicatorMap(): SafeHtml {
    let result: SafeHtml = '';
    result = this.url;
    return result;
  }

  isLoadMap() {
    return !this.status;
  }

  reloadMap(loaderTime) {
    this.loaderPostTime = loaderTime;
    const additionalOptions = {
      indicator: 'diagnostico',
      prevalence: 'Porcentaje',
      geo: 'COD_MUN',
      value: 0,
      scaleMin: this.scaleMin,
      scaleMax: this.scaleMax
    };
    this.url = '';
    this.status = false;
    additionalOptions.value = this.value;
    this.mapsService.get(this.session.getFilterOptions(additionalOptions)).subscribe(result => {
      this.url = result;
    });
  }

  mapLoaded() {
    setTimeout(() => {
      this.status = true;
    }, this.loaderPostTime);
  }
}
