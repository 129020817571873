import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {PrevalenceIndicatorsComponent} from './demo/pages/prevalence-indicators/prevalence-indicators.component';
import {ProcessIndicatorsComponent} from './demo/pages/process-indicators/process-indicators.component';
import {ResultIndicatorsComponent} from './demo/pages/result-indicators/result-indicators.component';
import {ClinicIndicatorsComponent} from './demo/pages/clinic-indicators/clinic-indicators.component';
import {DoctorIndicatorsComponent} from './demo/pages/doctor-indicators/doctor-indicators.component';
import {MedGeoDataComponent} from './demo/med-geo-data.component';
import {AccessGuard} from "./services/access-guard.service";


const routes: Routes = [
  { path: '',   redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./auth-signin-v2/auth-signin-v2.module').then(module => module.AuthSigninV2Module)
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AccessGuard],
    data: { requiresLogin: true },
    children: [
      { path: '',   redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'main',
        component: MedGeoDataComponent,
        children: [
          {
            path: 'dashboard-page',
            loadChildren: () => import('./demo/dashboard/dash-analytics/dash-analytics.module').then(module => module.DashAnalyticsModule)
          },
          {
            path: '',
            loadChildren: () => import('./demo/med-geo-data-module.module').then(module => module.MedGeoDataModuleModule)
          }
        ]
      },

    ]
  },
  {
    path: '**', redirectTo: 'login', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
