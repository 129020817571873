import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {RadarChartService} from "../../../services/radar-chart.service";
import {ApexChartService} from "../../../theme/shared/components/chart/apex-chart/apex-chart.service";
import {QueryOptions} from "../../../services/query-options";

@Component({
  selector: 'app-star-chart',
  templateUrl: './star-chart.component.html',
  styleUrls: ['./star-chart.component.scss']
})
export class StarChartComponent implements OnInit {
  chartConfig: any = null;
  private pIndicator = '';

  @Input() title: string;
  @Input()
  set indicator(value: string) {
    this.pIndicator = value;
    if (value !== null) {
      // call your stuff
      this.updateChart();
    }
  }

  constructor(private session: SessionService, private radarCharService: RadarChartService, private apexChartService: ApexChartService) {
  }

  ngOnInit() {
  }

  updateChart() {
    this.chartConfig = null;
    const options: QueryOptions = this.session.getFilterOptions({indicator: this.pIndicator});
    this.radarCharService.list(options).subscribe(
      result => {
        let colors: string[] = ['#5cb85c', '#ffdc73', '#F44336'];
        if (result[0].series.length < 3) {
          colors = ['#5cb85c', '#F44336'];
        } else if (result[0].series.length > 3) {
          colors = ['#5cb85c', '#ffdc73', '#ee8e3b', '#F44336'];
        }
        this.chartConfig = {
          colors,
          chart: {
            type: 'line',
            height: 350,
          },
          series: result[0].series,
          yaxis: [
            {
              decimalsInFloat: 2,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: '#FF1654'
              },
              labels: {
                style: {
                  colors: '#FF1654'
                }
              },
              title: {
                text: 'Porcentajes',
                style: {
                  color: '#FF1654'
                }
              }
            }
          ],
          tooltip: {
            shared: true,
          },
          xaxis: {
            type: 'datetime'
          },
          markers: {
            size: 1,
          }
        };
      });
  }

  isLoadChart() {
    return (this.chartConfig == null);
  }
}
