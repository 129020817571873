<div class="row">
  <div *ngFor="let measure of measures; let index = index" class="col-md-4 col-xl-4 measure-box">
    <app-card customHeader="true" [cardTitle]="measure.title"  [hidHeader]="false" footerClass="border border-top-0 p-0 overflow-hidden" [isCardFooter]="true">
      <div class="app-card-header" >
        <h5>{{ measure.title }}</h5>
      </div>
      <h4 class="m-0"><span class="ml-2" [ngClass]="measure.current.indicator == true?'text-c-green':'text-c-red'"><i class="feather" [ngClass]="measure.current.indicator == true?'icon-arrow-up':'icon-arrow-down'"></i>{{measure.current.percentege | number: '1.2-2'}}%</span></h4>
      <div class="app-card-footer">
        <div class="row">
          <div class="col-6">
            Entorno
          </div>
          <div class="col-6">
            Año anterior
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <span class="ml-2" [ngClass]="measure.percentile.indicator == true?'text-c-green':'text-c-red'"><i class="feather" [ngClass]="measure.percentile.indicator == true?'icon-arrow-up':'icon-arrow-down'"></i>{{measure.percentile.percentege | number: '1.2-2'}}%</span>
          </div>
          <div class="col-6">
            <span class="ml-2" [ngClass]="measure.lastYear.indicator == true?'text-c-green':'text-c-red'"><i class="feather" [ngClass]="measure.lastYear.indicator == true?'icon-arrow-up':'icon-arrow-down'"></i>{{measure.lastYear.percentege | number: '1.2-2'}}%</span>
          </div>
        </div>
      </div>
    </app-card>
    <app-map [title]="measure.title" [value]="index"></app-map>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <app-star-chart title="Prevalencia Diabetes" [indicator]="'prevalencia'"></app-star-chart>
  </div>
</div>
